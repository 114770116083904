import axios from 'axios';
import type { StoreProductDetails } from '../types';
import { baseApiUrl } from '../data'

const SHOPIFY_AUTHORIZATION_TOKEN = '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'; // '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'; //'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'
const store = 'shoe28'; //'colorblend-makeup-london'

const getProducts = async (store_name: string) => {
  const url = `${baseApiUrl}clients/${store_name}/products`

  try {
    const result = await axios.get(url, { headers: {
      'Access-Control-Allow-Origin' : '*',
     } });
    return result.data.products;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const getCart = async () => {
  const result = await fetch("/cart.json");

  if (result.status === 200) {
      return result.json();
  }

  throw new Error(`Failed to get request, Shopify returned ${result.status} ${result.statusText}`);
}

const addToCart = async (reco: StoreProductDetails[]) => {

  try {

    const items = reco.map(({variant, quantity}) => ({
      id: variant.id, 
      quantity,
      properties: {
        '_source': 'yuty'
      }
    }));
  
    if (items.length === 0) return;
  
    let formData = {
      'items': [...items]
     };
  
    const result = await fetch("/cart/add.js", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });
    const res = await result.json();
  
    if (result.status === 200) {
      return res;
    } else if(result.status === 422) {
      throw `${res.description}`
    } else {
      console.log('r', res)
      throw `Unable to add more of this specific item.` 
    }
  } catch (error: any) {
    console.log('errrror', error)
    throw `${error}`;
  }
}

const updateCart = async (key: string, quantity: number) => {
  try {

    let formData = {
      id: key, 
      quantity
     };
  
    const result = await fetch("/cart/change.js", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });
    const res = await result.json();
  
    if (result.status === 200) {
      return res;
    } else if(result.status === 422) {
      throw `${res.description}`
    } else {
      console.log('r', res)
      throw `Unable to add more of this specific item.` 
    }
    
  } catch (error: any) {
    console.log('shopify error', error)
    throw `${error}`;
  }

  
}

export default { getProducts, getCart, addToCart, updateCart };