<script setup lang="ts">
import { computed, ref } from 'vue';

const props = defineProps<{
    logo?: string
}>();

const emit = defineEmits(['ready'])

const imgPath = (name: string) => {
    return new URL(`../assets/${name}`, import.meta.url).href
}

const logo = computed(() => {
    return props.logo !== '' ? props.logo : imgPath('yuty-advisor-logo.svg')
});

const isChecked = ref(false);
const isWarning = ref(false);

const onCheck = () => {
    console.log('====>check=>>>new')
    isChecked.value = !isChecked.value;
}

const checkStyle = computed(() => ({
    backgroundColor: isChecked.value ? '#000' : '#fff'
}))

const proceed = () => {
    if (isChecked.value) {
        emit('ready');
        isWarning.value = false;
    } else {
        isWarning.value = true;
    }
}

</script>

<template>
    <div class="yt-terms-container_main">
        <div class="yt-terms-container">
            <div class="yt-terms_header">
                <img alt="logo" class="yt-client-logo" :src="logo" />
            </div>

            <div class="yt-terms-title">
                <p>As we live and breathe, we change and evolve, as do our beauty routines.</p>
                <p>Discover your perfect match based on you, today.</p>
            </div>

            <div class="yt-terms_steps">
                <div></div>
                <ul>
                    <li class="yt-terms_steps-row">
                        1. Take a selfie
                    </li>
                    <li class="yt-terms_steps-row">
                        2. Yuty analyses
                    </li>
                    <li class="yt-terms_steps-row">
                        3. Tell Yuty your goals & preferences
                    </li>
                    <li class="yt-terms_steps-row">
                        4. Browse perfect matches
                    </li>
                </ul>
            </div>

            <div class="yt-terms_check">
                <div class="yt-terms_check-box">
                    <button class="yt-terms_check-btn" @click="onCheck()" :style="checkStyle">
                        <img src="../assets/checkbox.svg" v-if="isChecked" />
                    </button>
                </div>
                <div class="yt-terms_check-content">
                    <!-- <div class="yt-terms_check-content-title">Agreement*</div> -->
                    <p>I grant YUTYBAZAR Limited the right to use my age, race, image, likeness and/or pictures of me for internal research purposes including the ability to update our Yuty algorithm to improve analysis and product recommendations. 
Please read  
                    <a href="https://www.yuty.me/termsconditions" target="_blank">Terms and Conditions</a>.</p>
                </div>
            </div>

            <div class="yt-terms_warning" v-if="isWarning"> Check Terms and Conditions to continue!</div>

            <button class="yt-terms-btn" @click="proceed()">
                START
            </button>
            
        </div>

    </div>
</template>
