import axios from 'axios';
import type { WishedProduct } from '@/utils/types';
import { baseApiUrl } from '../data';

const createWishlist = async (products: WishedProduct[], email: string, clientId: number, quizId: string) => {
  try {
    const url = baseApiUrl.concat('wishlists');

    const data = {
        products,
        email,
        quiz_id: quizId,
        client_id: clientId
    }
    const result = await axios.post(url, data);

    return result.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export default { createWishlist };