import axios from 'axios';
import type {Answer} from '@/utils/types';
import { baseApiUrl } from '../data';

const createQuiz =  async (answers: Answer[], quizType = 'makeup', client_id: number) => {
  const url = baseApiUrl.concat('quizes');

  try {
    const data = new FormData();
    data.append('quiz[quiz_type]', quizType);
    data.append('quiz[client_id]', client_id.toString());

    for (const e of answers) {
      data.append('quiz[question_answers_attributes][][question_id]', e.id);
      if (e.isFreetext) {
        data.append('quiz[question_answers_attributes][][freetext]', e.answers as string);
      } else if (e.answers.length > 1) {
        (e.answers as []).forEach((e) => {
          data.append('quiz[question_answers_attributes][][answer_option_ids][]', e);
        });
      } else
        data.append('quiz[question_answers_attributes][][answer_option_ids][]', e.answers as any);
    }

    const result = await axios.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    });
    const quiz = result.data.data;
    return quiz;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const updateAnswer = async (answers: Answer[], id: string) => {
  const url = baseApiUrl.concat(`quizes/${id}`);

  try {
    const data = new FormData();

    for (const e of answers) {
      if (e.isImage) continue;

      data.append('quiz[question_answers_attributes][][question_id]', e.id);

      if (e.isFreetext) {
        data.append('quiz[question_answers_attributes][][freetext]', e.answers as any);
      } else {
        (e.answers as []).forEach((e) => {
          data.append('quiz[question_answers_attributes][][answer_option_ids][]', e);
        });
      }
    }

    const result = await axios.patch(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    });
    const recommendationId = result.data.data.relationships.recommendation.data?.id || null;
    return recommendationId;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export default { createQuiz, updateAnswer }