import axios from 'axios';
import { baseApiUrl } from '../data';
const backendUrl = `${baseApiUrl}`;

const getClientDetails = async (store_name: string) => {
    const url = backendUrl.concat(`clients/${store_name}`);  
    
    try {
        const result = await axios.get(url, { headers: {
          'Access-Control-Allow-Origin' : '*',
         } });
        return result.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export default { getClientDetails }