import type {  EnvType, QuizType } from "../types";

export const quizTypeMap: QuizType[] = ['skin_care', 'hair_care', 'body_care', 'makeup', 'skin_adv', 'foundation_adv', 'fragrance'];

export const quizTypeApiMap = {
    'skin_care':  {
        baseUrl: 'https://skin-engine-hrqpec7zsq-lm.a.run.app/v1/', 
        imagePath: 'skin_image',
        recoPath: 'skin_engine',
        type: 'skin',
        title: 'Skin'
    },
    'hair_care': {
        baseUrl: 'https://hair-engine-h35qlo53yq-ew.a.run.app/v1/', 
        imagePath: '',
        recoPath: 'hair_engine',
        type: 'hair',
        title: 'Hair'
    }, 
    'body_care': {
        baseUrl: 'https://body-engine-397409-krr3qt663q-ew.a.run.app/v1/', 
        imagePath: '',
        recoPath: 'body_engine',
        type: 'body',
        title: 'Body'
    }, 
    'makeup': {
        baseUrl: 'https://makeup-engine-ffvndakala-lm.a.run.app/v1/', 
        imagePath: 'makeup_image',
        recoPath: 'makeup_engine',
        type: 'makeup',
        title: 'Make up'
    },
    'foundation_adv': {
        baseUrl: 'https://makeup-engine-ffvndakala-lm.a.run.app/v1/', 
        imagePath: 'upload_image',
        recoPath: 'makeup_image',
        type: 'makeup',
        title: 'Foundation'
    },
    'fragrance': {
        baseUrl: 'https://tqzwo26fpbhvlu34raqz6xrmy40ikixp.lambda-url.us-west-2.on.aws/v1/', 
        imagePath: '',
        recoPath: '',
        type: 'fragrance',
        title: 'Fragrance'
    }, 
    'skin_adv': {
        baseUrl: 'https://skin-engine-hrqpec7zsq-lm.a.run.app/v1/', 
        imagePath: 'skin_image',
        recoPath: 'skin_image',
        type: 'skin',
        title: 'Skin'
    }
}

type EnvObj = {
    production: string,
    staging: string,
    dev: string
}

export const envObject: EnvObj = {
    production: 'https://gcp-api.yuty.me/api/v1/',
    staging: 'https://gcp-api-staging.yuty.me/api/v1/',
    dev: 'http://127.0.0.1:3001/api/v1/'
}
export const env_: EnvType = 'production' // 'dev/ || 'staging' || 'production'
export const baseApiUrl = envObject[env_];