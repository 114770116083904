<script setup lang="ts">
import { computed, ref, reactive, type Ref, onMounted } from 'vue';
import WidgetModal from './WidgetModal.vue';
import { getClientDetails } from '../utils/api';
import type { ClientDetails } from '../utils/types'

const props = defineProps<{
    showLogo?: "true" | "false",
    btnLabel?: string,
    btnFontSize?: string,
    btnFontWeight?: number,
    btnBorder?: string,
    btnBorderRadius?: string,
    btnColor?: string,
    btnBackgroundColor?: string,
    btnBoxShadow?: string,
    btnPadding?: string,
    btnHeight?: string,
    btnWidth?: string,
    store?: string
}>()

const isOpen: Ref = ref(false);
const logoStr = ref('');
const storeName = ref('yuty');
let client = reactive<ClientDetails>({
  id: 0,
  name: '',
  store_name: '',
  api_base_url: '',
  api_token: '',
  website: '',
  categories: [3],
  logo: ''
});
// Launch button styles
const launchBtnObject = reactive({
    height: '60px',
    width: 'auto',
    fontSize: '18px',
    fontWeight: 500,
    border: 'none',
    borderRadius: '5px',
    color: '#000000',
    backgroundColor: '#fff',
    boxShadow: 'rgb(0 0 0 / 20%) 2px 4px 7px 2px, rgb(0 0 0 / 9%) 0px 1px 2px 0px',
    padding: '10px 20px',
    cursor: 'pointer'
});
const buttonLabel = computed(() => {
    return props.btnLabel ? props.btnLabel : "Find Personalised Matches";
});

const showLogoBtn = computed(() => props.showLogo && props.showLogo === "true" ? true : false)

const toggleModal = (): void => {
  isOpen.value = !isOpen.value;
}

const getStoreDetails = async () => {
    try {
        const client_ = await getClientDetails(storeName.value);
        client = {...client_}
        logoStr.value = client.logo
    } catch (error) {
        console.error(error)
    }
}

onMounted(() => {
  /*
  *  Add mediapipe cdn scripts as mediapipe npm packages are throwing errors.
  */
  let drawingUtils = document.createElement('script')
  let faceMesh = document.createElement('script')
  drawingUtils.setAttribute('src', 'https://cdn.jsdelivr.net/npm/@mediapipe/drawing_utils/drawing_utils.js')
  faceMesh.setAttribute('src', 'https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/face_mesh.js')
  document.head.appendChild(drawingUtils)
  document.head.appendChild(faceMesh)

  const { store, btnFontSize, btnFontWeight, btnBorder, btnBorderRadius, btnColor, btnBackgroundColor, btnBoxShadow, btnPadding, btnHeight, btnWidth, } = props

  if (store) {
    storeName.value = store
  }

  const launchBtnObject_ = {
    ...launchBtnObject,
    ...(btnHeight && {height: btnHeight}),
    ...(btnWidth && {width: btnWidth}),
    ...(btnFontSize && {fontSize: btnFontSize}),
    ...(btnFontWeight && {fontWeight: btnFontWeight}),
    ...(btnBorder && {border: btnBorder}),
    ...(btnBorderRadius && {borderRadius: btnBorderRadius}),
    ...(btnColor && {color: btnColor}),
    ...(btnBackgroundColor && {backgroundColor: btnBackgroundColor}),
    ...(btnBoxShadow && {boxShadow: btnBoxShadow}),
    ...(btnPadding && {padding: btnPadding}),
  }

  Object.assign(launchBtnObject, launchBtnObject_)

  getStoreDetails();
})

</script>

<template>
  <button :style="launchBtnObject" class="yt-launch-btn" @click="toggleModal">
    <img src="https://uploads-ssl.webflow.com/64f7275da76674b3f59eebdb/64f75c3205571fc119971be4_yuty-logo.svg" class="yt-launch-btn-img" v-if="showLogoBtn" />
    {{ buttonLabel }}
</button>
  <WidgetModal :logo="logoStr" :isOpen="isOpen" :client="client" @toggle-modal="toggleModal" v-if="isOpen && client.store_name !== ''"/>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@500;700&display=swap');

.yt-launch-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.yt-launch-btn-img {
    height: 24px;
    margin: 0 20px 0 0;
}
/* 
*  Widget modal styles
 */

 .yt-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000000;;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0303038f;
}

.modal-fade-enter, .modal-fade-leave-to {
  opacity: 0;
}
 
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease;
}


.yt-modal {
  position: relative;
  text-align: center;
  background-color: white;
  width: 98vw;
  height: 92vh;
  border-radius: 20px;
  font-family: sans-serif;
  color: #000000;
}

.yt-header {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.yt-bt-close {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 1px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.yt-bt-close > img {
  width: 30px;
  height: auto;
}

.yt-container {
  width: 100%;
  height: auto;
  background-color: #fff;
  position: absolute;
  top: 60px;
  bottom: 30px;
  left: 0px;
  right: 0px;
}

.yt-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 30px;
  padding: 5px;
  color: #000000;
  background-color: #e6e4e1;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: Assistant, sans-serif;
  font-weight: 500;
}

.yt-logo {
  width: 38px;
  height: auto;
  margin-left: 5px;
}

.yt-client-logo {
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 50px;
}

@media (min-width: 900px) {
  .yt-modal {
    width: 820px;
    height: 590px;
  }

  .yt-container {
    width: 100%;
    height: auto;
    background-color: #fff;
  }
}

/* 
*  ==== Question styles
 */

 /* 
 * ==== AnswerSelect
  */
.yt-questions-container {
    padding: 0em 1em;
    color: #0e0707;
    height: 500px;
    position: relative;
}

.yt-question-body {
    height: 450px;
}

.yt-progress-container {
    width: 100%;
    padding: .01em 0em;
    text-align: left;
}

.yt-progress-info {
    font-size: 12px;
    font-weight: 500;
    font-family: 'Assistant', sans-serif;
    text-transform: uppercase;
    color: #0e0707;
    padding: .2em 0em;
}

.yt-progress-cont {
    border: 1px solid #bab8b8;
    height: 4px;
    margin: .3em 0em;
    background-color: #eae9e9;
}

.yt-progress-bar {
    background-color: #0e0707;
    height: 3px;
}

.yt-question-title {
    width: 100%;
    min-height: 3em;
    color: #000;
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
    padding: .4em 0em .2em;
    line-height: 24px;
}

.yt-question-footer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.yt-quiz-choices_container {
    height: 355px;
    overflow: auto;
}

.yt-choice-container {
    width: 100%;
    height: 339px;
    padding: .5em 0em 3em;
    overflow-y: auto;
}

.yt-choice-row {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 5px;
}

.yt-choice-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80px;
    border: 1px solid #cecece;
    background-color: inherit;
    color: #000000;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Nunito';
    text-transform: uppercase;
    padding: 10px;
    margin-right: .2em;
    margin-bottom: .5em;
}

.yt-choice-btn > span {
    text-transform: capitalize;
    color: #040404;
    font-size: 16px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
}

.yt-choice-btn__image-container {
    margin-right: .2em;
    margin-bottom: .5em;
}

.yt-choice-btn__image-top {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    border: 1px solid #cecece;
    background-color: inherit;
    color: #000000;
    font-size: 12px;
    font-weight: 700;
    font-family: 'Nunito';
    text-transform: uppercase;
    padding: .5em .3em;
}

.yt-choice-btn__image-top > img {
    height: auto;
    width: 70%;
    max-height: 150px;
}

.yt-choice-btn__image-top > .span {
    position: absolute;
    top: 5px;
    right: 5px;
}

.yt-choice-btn__image-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3em;
    background-color: inherit;
    color: #000000;
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 700;
    padding: .3em;
}

.yt-choice-btn:hover, .yt-choice-btn__image-top:hover {
    cursor: pointer;
    background-color: #eeececeb;
    color: #5f5d5d;
}

.yt-choice-btn-active {
    border: 1px solid #000;
}

.yt-nav-container {
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: 0;
    height: 50px;
    padding: 2px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
}

.yt-nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: inherit;
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    text-transform: capitalize;
    padding: 5px 10px;
    cursor: pointer;
    color: #000000;
    height: 40px;
}

.yt-nav-btn:hover {
    background-color: #eeececeb;
}

.yt-nav-btn-disabled {
    cursor: not-allowed;
    color: #6e6c6c
}

.yt-nav-icon-left {
    width: 1.3em;
    height: auto;
    margin-right: .1em;
}

.yt-nav-icon-right {
    width: 1.3em;
    height: auto;
    margin-left: .1em;
}

@media (max-width: 900px) {
    .yt-question-body {
        height: 100%;
    }
    
    .yt-questions-container {
        height: 100%;
    }

}

@media (min-width: 500px) {

    .yt-choice-row {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .yt-question-title {
        font-size: 20px;
    }

    .yt-choice-row {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    } 
}

@media (max-width: 900px) and (max-height: 915px) {

    .yt-choice-container {
        height: 68vh;
        overflow-y: auto;
        overflow-x: auto;
    }
    
}

@media (max-width: 900px) and (max-height: 896px) {

    .yt-choice-container {
        height: 68vh;
        overflow-y: auto;
        overflow-x: auto;
    }
    
}

@media (max-width: 900px) and (max-height: 786px) {

    .yt-choice-container {
        height: 65vh;
        overflow-y: auto;
        overflow-x: auto;
    }
}

@media (max-width: 900px) and (max-height: 745px) {

    .yt-choice-container {
        height: 64vh;
        overflow-y: auto;
        overflow-x: auto;
    }
}

@media (max-width: 900px) and (max-height: 668px) {

    .yt-choice-container {
        height: 63vh;
        overflow-y: auto;
        overflow-x: auto;
    }
    
}

@media (max-width: 900px) and (max-height: 568px) {

    .yt-choice-container {
        height: 54vh;
        overflow-y: auto;
        overflow-x: auto;
    }
    
}

/* 
* ==== AllergenSelect and === CitySelect
*/

 .quiz-multiselect {
    max-width: 80%;
    width: 30em;
    height: 310px;
    margin: 0 auto;

    /* --ms-caret-color: #fff; */
  }

@media (max-width: 900px) {

.quiz-multiselect {
   max-width: 100%;
   width: 100%;
   height: 330px;
   margin: 0 auto;

   /* --ms-caret-color: #fff; */
 }

}
  
.quiz-multiselect__answers {
    margin: 0 auto 2em 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5em;
}
  
.quiz-multiselect__answers--images {
    grid-template-columns: repeat(6, 1fr);
    justify-items: center;
    text-align: center;
}
  
.quiz-multiselect__answers--images > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.quiz-multiselect__answer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #fff;
    border: 1px solid #cecece;
    padding: 1em;
    min-height: 80px;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}
  
.quiz-multiselect__answer > span {
    padding: 0 0.5em;
    font-size: 16px;
    font-family: 'Assistant', sans-serif;
    font-weight: 700;
}
  
.quiz-multiselect__answer--selected {
    border-color: #000;
}
  
.quiz-multiselect__title {
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 700;
}
  
.allergen-mutliselect.multiselect {
    font-family: 'Montserrat';
    color: #000;
    box-shadow: none;
}
  
.multiselect__input::placeholder {
    color: #000;
}

.multiselect-tags {
    border-color: #000;
    border-radius: 0;
    padding: 8px 8px 0;
}

.multiselect-tag {
    background: #111;
    color: #fff;
    border-radius: 5px;
    min-height: 25px;
    font-size: 14px;
    font-family: Assistant, sans-serif;
    font-weight: 500;
}

.multiselect-tag-icon:hover {
    background: #000;
}

.multiselect-tag-icon::after {
    color: #fff;
}

.multiselect__select {
    display: none;
}

.multiselect__content-wrapper {
    border-color: #cecece;
}

.multiselect__option--highlight {
    background: #111;
}

.multiselect-spinner:before,
.multiselect-spinner:after {
    border-color: #111 transparent transparent;
    z-index: 1;
}

.multiselect-single-label-text {
    font-size: 14px;
    font-family: Assistant, sans-serif;
    background-color: #fff;
    color: #000;  
}

.multiselect-options > ul {
    background-color: #040404;
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
}

.is-pointed, .is-selected {
    background: #040404;
    color: #fff;
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
}

.is-pointed:hover, .is-selected:hover {
    background: #040404;
    color: #fff;
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
}

/* 
* ==== FreeText
 */

.quiz-freetext {
  max-width: 80%;
  width: 30em;
  margin: 0 auto;
  height: 355px;
}

.quiz-freetext__title {
  font-size: 14px;
  font-weight: 700;
}

.quiz-freetext__textarea {
  width: 100%;
  height: 10em;
  resize: none;
}

/* 
* ==== End Questions styles
 */

 /* 
 * ==== Selfie start
  */

.yt-selfie-container {
    height: 500px;
}

.yt-selfie-content {
    width: 100%;
    height: 430px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.yt-selfie-content_header {
    padding: .5em .3em 1em;
}

.yt-selfie-scroll {
    overflow-x: scroll;
    overflow-y: hidden;
    height: auto;
}

.yt-selfie-row {
    display: flex;
    height: 370px;
    width: 800px;
}

.yt-selfie-cam-row {
    display: flex;
    height: 405px;
    width: 800px;
    border: 1px solid #000;
}

.yt-selfie-image_col {
    width: 370px;
    border: 1px solid #000000;
    position: relative;
    background-color: #000;
}

.yt-selfie-image_col-header {
    background-color: #0e0707;
    color: #fff;
    font-size: 14px;
    font-family: Assistant,sans-serif;
    font-weight: 500;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.yt-selfie-image_col-media {
    position: relative;
    width: 100%;
    background-color: #000;
    height: auto;
}

.yt-selfie-image_col-media > video {
    width: 100%;
    height: auto;
}

video {
  transform: scale(-1,1);
}
video::-webkit-media-controls-panel {
 transform: scale(-1,1);
}

.yt-selfie-image_canvas {
    width: 100%;
    height: 100%;
}

.yt-selfie-msg {
    position: absolute;
    top: 2.5em;
    left: 1em;
    width: 100%;
    text-align: center;
}

.yt-selfie-msg > p {
    color: red;
    font-size: 12px;
    font-family: Assistant,sans-serif;
    font-weight: 700;
    line-height: 16px;
    margin: 0;
}

.yt-selfie-frame {
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.yt-selfie-frame > img {
    border: none;
    height: 5em;
}

.yt-camera_btn-row {
    position: absolute;
    bottom: 1em;
    left: 0%;
    right: 0%;
    display: flex;
    justify-content: center;
    z-index: 10;
}

.yt-camera_btn {
    background: none;
    border: none;
    width: 60px;
}

.yt-camera_btn:hover {
    cursor: pointer;
}

.yt-camera_btn > img {
    width: 100%;
}

.yt-selfie-image_col-img {
    flex: 1;
    background-color: #e6e4e1;
}

.yt-selfie-image_col-img > img {
    width: 100%;
    height: auto;
}

.yt-slefie-tips_col {
    flex: 1;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.yt-slefie-tips_header {
    border-bottom: 1px solid #000;
}

.yt-slefie-tips_header, .yt-slefie-tips_footer {
    height: 35px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: 'Assistant', sans-serif;
    font-weight: 700;
}

.yt-slefie-tips_footer {
    border: none;
    border-top: 1px solid #000;
}

.yt-slefie-tips_footer:hover {
    cursor: pointer;
}

.yt-slefie-tips_content {
    flex: 1;
    text-align: left;
    padding: 1em;
    line-height: 24px;
}

.yt-slefie-tips_content-title {
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
    padding-bottom: 10px;
}

.yt-slefie-tips_reco {
    display: flex;
    padding-left: 1em;
}

.yt-slefie-tips_reco-label {
    margin-right: .3em;
}

.yt-slefie-tips_reco-label, .yt-slefie-tips_reco-value {
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
}

.yt-selfie-footer {
    display: flex;
    align-items: center;
    height: 80px;
}

.yt-selfie-footer_col-left, .yt-selfie-footer_col-right {
    flex: 1;
}

.yt-selfie-footer_col-right {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.yt-selfie-footer_btn {
    background-color: inherit;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
    margin: .2em
}

.yt-selfie-footer_btn > img {
    height: 1em;
}

.yt-selfie-footer_btn:hover {
    cursor: pointer;
}

.yt-selfie-loader {
    height: 20em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 2em;
}

.yt-selfie-loader > .yt-selfie-img {
    width: auto;
    height: 100%;
}

.yt-ring-loader_container {
    position: absolute;
    left: 0%;
    top: 0%;
    height: 100%;
    width: 100%;
    opacity: .9;
    background-color: #6e6c6ccc;
}

.yt-ring-loader_container > img {
    height: 100%;
    width: 100%;
}

@media (max-width: 900px) and (max-height: 915px) {

    .yt-selfie-container {
        height: 75vh;
    }
    
}

@media (max-width: 900px) and (max-height: 786px) {

    .yt-selfie-container {
        height: 72vh;
    }
    
}

@media (max-width: 900px) and (max-height: 668px) {

    .yt-selfie-container {
        height: 70vh;
    }
    
}

@media (max-width: 900px) {

    .yt-selfie-content {
        height: 100%;
    }

    .yt-selfie-footer {
        height: 60px;
    }

    .yt-selfie-scroll {
        height: 100%;
        padding-bottom: 10px;
    }

    .yt-selfie-image_col {
        width: auto;
        flex-direction: column;
        height: auto;
        background-color: #fff;
        border: none;
    }

    .yt-selfie-image_col-media > video {
        width: 99.7%;
        height: auto;
    }

    .yt-selfie-image_canvas {
        width: 99.7%;
        height: 100%;
    }

    .yt-selfie-row { 
        flex-direction: column;
        width: auto;
        height: 400px;
        overflow-y: auto;
        padding: 0.3em;
    }

    .yt-selfie-cam-row {
        flex-direction: column;
        height: 100%;
        width: auto;
        overflow-y: auto;
        padding: 0.3em;
        border: none;
        padding-bottom: 5px;
    }

    .yt-slefie-tips_col {
        padding-top: 2px;
        padding-bottom: 5rem;
    }

    .yt-slefie-tips_header {
        border: none;
    }

    .yt-slefie-tips_content {
        padding-top: 0;
    }

    .yt-slefie-tips_footer {
        border: 1px solid #000;
    }

}

/* 
* ==== Selfie Ends
 */

 /* 
 * === Recommendations
  */


.yt-recommendation-container {
    padding: .5em 0em 0em 0em;
}

.yt-reco-tab_nav {
    width: 100%;
    display: flex;
    margin-bottom: .5em;
}

.yt-reco-tab_nav > button {
    width: 100%;
    height: 2.5em;
    border-radius: none;
    border: none;
    background-color: inherit;
    border-bottom: 2px solid #929471;
}

.yt-reco-tab_nav > button:hover {
    cursor: pointer;
}

.yt-reco-tab_nav-active {
    border-bottom: 2px solid #000000;
}

.yt-divider {
    margin: .2em 0em 1em 0em;
    width: 100%;
    height: 2px;
    background-color: #0e0707;
}

.yt-recommendation-section {
    height: 340px;
    width: 100%;
}

@media (max-width: 900px) {
    .yt-recommendation-section {
        overflow-x: scroll;
    }  
}

.yt-recommendation-row {
    display: flex;
    width: 800px;
    height: 100%;
    padding-right: 1em;
}

.yt-products-container {
    width: 42%;
    height: 100%;
    margin-right: 1em;
}

.yt-products-header {
    background-color: #0e0707;
    color: #fff;
    font-size: 14px;
    font-family: 'Assistant',sans-serif;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
}

.yt-products-list {
    height: 400px;
}

.yt-products-scroll-y {
    overflow-y: scroll;
    height: 310px;
    border-right: 1px solid #5d5353;
    padding-right: 0px;
}

.yt-products-scroll-y::-webkit-scrollbar {
    width: 10px;
}

.yt-products-scroll-y::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(12, 9, 9, 0.3);
    border-left: 1px solid #0e0707;
}

.yt-products-scroll-y::-webkit-scrollbar-thumb {
    background-color: #0e0707;
    height: 20px;
}

.yt-products-list-card {
    display: flex;
    min-height: 4px;
    border: 1px solid #5d5353;
    border-left: 1px solid #fff;
    border-top: none;
}

.yt-products-card-btns {
    min-height: 4px;
    width: 2em;
    display: flex;
    flex-direction: column;
}

.yt-card-btn {
    border: none;
    flex: 1;
    width: 100%;
    cursor: pointer;
}

.yt-btn-plus {
    background-color: #0e0707;
    color: #fff;
}

.yt-btn-minus {
    background-color: #7f7c7c;
    color: #fff;
}

.yt-products-card-body {
    flex: 1;
    display: flex;
}

.yt-products-card-body:hover {
    cursor: pointer;
    background-color: rgb(233, 233, 233);
}

.yt-products-card-image {
    width: 3em;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.yt-products-card-image > img {
    width: 100%;
    height: auto;
}

.yt-products-card-info {
    flex: 1;
    display: flex;
    align-items: center;
    text-align: left;
    padding: 2px;
}

.yt-products-card-info-title {
    font-size: 12px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    color: #000000;
    line-height: 20px;
}

.yt-products-card-info-subtitle {
    font-size: 10px;
    font-weight: 400;
    color: #000000;
}

.yt-products-card-quantity {
    width: 3em;
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
    text-align: left;
    padding: 2px 1px;
    display: flex;
    align-items: center;
}

.yt-products-card-icon {
    width: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.yt-products-card-icon > img {
    width: 1em;
    height: auto;
}

.yt-product-info-container {
    flex: 1;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 0em .4em .4em;
    background-color: #0e0707;
}

.yt-product-info-body {
    display: flex;
    flex: 1;
    width: 100%;
    background-color: #fff;
}

.yt-product-info-left {
    display: flex;
    flex-direction: column;
    width: 50%;
    border-right: 1px solid #0e0707;
}

.yt-product-image {
    position: relative;
    flex: 1;
    max-height: 150px;
    text-align: center;
    border-bottom: 1px solid #0e0707;
    display: flex;
    align-items: center;
    justify-content: center;
}

.yt-product-image-img {
    width: auto;
    height: 120px;
}

.yt-product-image-title {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #000;
    color: #fff;
    padding: 2px;
    font-size: 12px;
    font-family: Assistant, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

.yt-product-image-wishlist {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: inherit;
}

.yt-product-image-wishlist:hover {
    cursor: pointer;
}

.yt-product-image-wishlist > img {
    width: 1em;
    height: auto;
}

.yt-product-basic {
    padding: 5px;
    text-align: center; 
    border-bottom: 1px solid #0e0707;
}

.yt-product-basic-title {
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    color: #000;
    line-height: 20px;
    padding: 3px;
}

.yt-product-basic-subtitle {
    font-size: 12px;
    font-weight: 400;
    color: #0e0707;
}

.yt-product-basic-price {
    font-size: 12px;
    font-family: 'Assistant', sans-serif;
    font-weight: 700;
}

.yt-product-cart-btn {
    width: 100%;
    border: none;
    background-color: inherit;
    height: 2.5em;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.yt-product-cart-btn:hover {
    cursor: pointer;
    background-color: #b8b6b6;
}

.yt-product-info-right {
    width: 50%;
    padding: 1em 1em 2em;
}

.yt-product-info-column {
    height: 230px;
    overflow: auto;
}

.yt-product-info-desc {
    text-align: left;
    padding: .3em 0;
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
}

.yt-product-info-desc > .title {
    font-size: 12px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
    color: #5d5353be;
}

.yt-product-info-desc > .yt-product-info-desc_info {
    font-size: 12px;
    font-family: Assistant, sans-serif;
    line-height: 20px;
}

.yt-product-info-desc_info * {
    font-size: 12px !important;
    font-family: Assistant, sans-serif !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    font-size: 12px !important;
}

.yt-product-info-list {
    text-align: left;
    padding: .3em 0;
    min-height: 3em;
}

.yt-product-info-list > div {
    font-size: 12px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
    color: #5d5353be;
}

.yt-product-info-list > p, .yt-product-info-list > span {
    font-size: 12px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
    color: #0a0606;
    margin: 0em;
}

.yt-checkout-container {
    display: flex;
    width: 100%;
    height: 3em;
    border: 1px solid #0e0707;
    border-left: none;
    border-right: none;
    background: #fff;
}

.yt-checkout-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
    padding: 0 5px;
}

.yt-checkout-info > span {
    font-size: 18px;
    font-family: 'Assistant', sans-serif;
    font-weight: 700;
    margin-right: 5px;
}

.yt-checkout-info > p {
    color: #0e0707;
    font-size: 12px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
    max-width: 12em;
    text-align: left;
    line-height: 12px;
    margin: 0;
}

.yt-checkout-btn {
    flex: 1;
    height: 100%;
    border: none;
    border-left: 2px solid #0e0707;
    background-color: inherit;
    text-transform: uppercase;
    font-size: 18px;
    font-family: 'Assistant', sans-serif;
    font-weight: 700;
}

.yt-checkout-btn:hover {
    cursor: pointer;
    background-color: #b8b6b6;
}

.yt-wishlist {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: inherit;
}

.yt-wishlist_form {
    display: flex;
    height: 40px;
    align-items: center;
}

.yt-wishlist_form-is {
    border: 1px solid #000;
    border-radius: 5px;
}

.yt-wishlist__input {
    background-color: inherit;
    height: 100%;
    width: 20em;
    border: none;
    transition: 2s;
    font-family: 'Assistant', sans-serif;
    font-size: 14px;
}

.yt-wishlist__input:focus {
    outline: none;
}

.yt-wishlist__input::placeholder {
    font-family: 'Assistant', sans-serif;
    font-size: 14px;
}

.yt-wishlist__btn {
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    border: none;
    background-color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.yt-wishlist__btn:hover {
    cursor: pointer;
    background-color: #b8b6b6;
}

.yt-wishlist__btn-active {
    background-color: #e6e4e1;
    border-left: 1px solid #000;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.yt-wishlist-icon {
    width: 1em;
    height: auto;
    margin: 0em 1em;
}

.yt-wishlist__btn > span {
    display: block;
}

@media (max-width: 900px) {
    .yt-wishlist__btn > span {
        display: none;
    }
    
}

.yt-analysis-container {
    padding: .5em 1em;
    margin-bottom: 2em;
}

.yt-analysis-container_row {
    width: auto;
    height: 370px;
    display: flex;
    border: 1px solid #0E0707;
}

.yt-analysis-column_image {
    width: 370px;
    height: auto;
    border-right: 1px solid #0E0707;
}

.yt-analysis-column_media {
    height: 340px;
    width: 100%;
    overflow-y: auto;
}

.yt-analysis-column_media > img, canvas {
    width: auto;
    height: auto;
    max-width: 100%;
}

@media (max-width: 900px) {

    .yt-analysis-container {
        height: 430px;
        overflow-y: auto;
    }

    .yt-analysis-container_row {
      flex-direction: column;
      height: auto;
      width: auto;
    } 

    .yt-analysis-column_image {
        width: auto;
        height: auto;
        border: none;
    }

    .yt-analysis-column_media {
        width: auto;
        height: auto;
    }

    .yt-analysis-column_media > img, canvas {
        width: 100%;
        height: auto;
    }

    .yt-analysis-column_info {
        flex: none;
    }
}

.yt-analysis-column_info {
   flex: 1;
   padding: 1em;
   overflow-y: auto;
}

.yt-analysis-info {
    font-size: 16px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
    text-align: left;
    color:#000000;
    line-height: 24px;
} 

.yt-analysis-stats {
    padding: 1em .5em 1em 1em;
    color: #000000;
}

.yt-analysis-stats_row {
    display: flex;
    align-items: center;
}

.yt-analysis-stats_row-value {
    width: 2em;
    margin-right: .3em;
}

.yt-analysis-stats_row-value, .yt-analysis-stats_row-text {
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
    text-align: left;
}

.yt-analysis-stats_row-color {
    margin-right: .5em;
    height: 1em;
}

.yt-analysis-concerns-preference-row {
    display: block;
}

.yt-analysis-concerns, .yt-analysis-preference {
    flex: 1;
    text-align: left;
}

.yt-analysis-preference {
    margin-top: 10px;
}

.yt-analysis-label, .yt-analysis-value {
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
    text-align: left;
    line-height: 20px;
}

.yt-analysis-label {
    color: #8F8F8F;
    margin-right: .3em;
}

.yt-analysis-value {
    color: #000000;
}

.yt-analysis-value > span {
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
}

.yt-location {
    text-align: left;
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
    text-align: left;
    line-height: 20px;
    color: #000000;
    margin: .7em 0;
}

.yt-analysis-container_row-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3em;
    border-top: none;
    margin-bottom: 1em;
}

.yt-analysis-btn_start {
    background-color: inherit;
    height: 2.5em;
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 700;
    border: none;
}

.yt-analysis-btn_start:hover {
    cursor: pointer;
}

.yt-reco-footer {
  height: 2em;
  padding: 5px;
  color: #000000;
  background-color: #e6e4e1;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.yt-camera-prompt {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.yt-camera-prompt > p {
    font-size: 14px;
    color: #040404;
    margin: 0;
}

.yt-camera-prompt > button {
    width: 13em;
    height: 3em;
    font-size: 14px;
    font-weight: 700;
}

/* 
* === Recommendations ENDs
 */

 /* 
 * ==== Terms and condition
  */

  .yt-terms-container_main {
    width: auto;
    height: 490px; 
    overflow: auto;
  }

  .yt-terms-container {
    padding: .5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 24px;
}

.yt-terms_header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em 1em 2em;
}

.yt-terms_header > .yt-client-logo {
    height: auto;
    width: auto;
    max-width: 250px;
    max-height: 90px;
}

.yt-terms-title {
    padding-bottom: 1em;
    width: 85%;
    line-height: 24px;
}

.yt-terms-title > p {
    font-size: 18px;
    color: #000;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
    margin: 0;
}

.yt-terms_steps {
    padding-bottom: 1em;
    text-align: left;
    display: flex;
    justify-content: center;
    width: 80%;
}

.yt-terms_steps > div {
    width: 23%;
}

.yt-terms_steps > ul {
    width: 50%;
    padding: 0;
}

.yt-terms_steps-row {
    font-size: 16px;
    font-family: 'Assistant', sans-serif;
    list-style-type: none; 
}

.yt-terms_check {
    display: flex;
    width: 80%;
}

.yt-terms_check-box {
    width: 25px;
}

.yt-terms_check-btn {
    height: 18px;
    width: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #000;
    margin-right: .5em;
    margin-top: .5em;
    background: none;
}

.yt-terms_check-btn > img {
    width: 18px;
    height: 18px;
}

.yt-terms_check-content {
    flex: 1;
    text-align: left;
    padding-left: 5px;
}

.yt-terms_check-content-title {
    font-family: 'Assistant', sans-serif;
    font-weight: 700;
    font-size: 18px;
}

.yt-terms_check-content > p > a {
    padding-left: .2em;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
    font-size: 12px;
    text-decoration: underline;
    color: #000;
}

.yt-terms_check-content > p {
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    margin: 0;
}

.yt-terms_warning {
    font-size: 12px;
    color: coral;
    padding: .3em;
}

.yt-terms-btn {
    height: 45px;
    width: 90px;
    margin-top: 1em;
    background-color: inherit;
    border: 1px solid #000;
    color: #000;
    font-size: 18px;
    font-family: 'Assistant', sans-serif;
    font-weight: 700;
}

.yt-terms-btn:hover {
    background-color: #F3F3F3;
    cursor: pointer;
}

@media (max-width: 900px) {

    .yt-terms-container_main {
        width: auto;
        height: 76vh; 
        overflow: auto;
    }

    .yt-terms_header {
        padding: 1em 1em 2em;
    }

    .yt-terms_check {
        width: 90%;
    }

    .yt-terms-title, .yt-terms_steps-row  {
        font-size: 14px;
        font-family: 'Assistant', sans-serif;
        text-align: left;
        width: 90%;
    }

    .yt-terms_steps {
        width: 100%;
    }

    .yt-terms_steps > div {
        width: 60px;
    }

    .yt-terms_steps > ul {
        flex: 1;
    }

    .yt-terms_check > span {
        font-size: 12px;
    }
}

/* 
* === Terms ends
 */

 /* 
 * === Tooltip
  */

  .yt-tooltip {
    position: relative;
    display: flex;
    width: auto;
    padding: 5px 0;
  }

    /* Tooltip text */
  .yt-tooltip .yt-tooltiptext {
    visibility: hidden;
    background-color: #fff;
    color: #040404;
    text-transform: capitalize;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #cecece;
    
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }

  .yt-tooltip_img {
      width: 20px;
      margin-left: 15px;
  }

    /* Show the tooltip text when you mouse over the tooltip container */
  .yt-tooltip:hover .yt-tooltiptext {
    visibility: visible;
  }
  
  .yt-tooltiptext { 
    min-width: 12rem;
    width: auto;
    padding: 10px;
    top: 100%;
    left: 0;
    margin-left: -60px;
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
  }

  .yt-tooltip .yt-tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #cecece transparent;
  }


  .popper {
    background-color: #ffffff !important;
    color: #333333 !important;
    border: 1px solid #eeeeee !important;
    border-radius: 6px !important;
    padding: 5px !important;
    box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25) !important;
    margin: 0px !important;
    width: auto !important;
  }

  .quiz__answer .quiz__tooltip span {
    padding: 0;
  }
  
  .quiz__tooltip {
    cursor: pointer;
    width: 3em;
    margin-left: .3em;
  }

  .quiz__tooltip > div {
      min-height: 15px;
      min-width: 10em;
      margin: 0 5px;
  }

  .quiz__tooltip-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .quiz__tooltip-row > span {
    padding: 0 .2em;
    flex: 1;
  }
  
 .quiz__tooltip-icon {
    height: 100%;
  }
  
  .quiz__tooltip-text {
    max-width: 12em;
    font-family: 'Montserrat';
    font-size: 10px;
    font-weight: 400;
    border: 1px solid #cecece;
    padding: 0.2em;
    background: #fff;
    box-shadow: none;
  }
  
  .quiz__tooltip-text > .popper__arrow {
    border-color: transparent transparent #cecece transparent !important;
  }

  /* 
  * === Tooltip ends
   */

   /* 
   * === Circular loader
    */

    .yt-circular-loader_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.yt-loader_container {
    width: 8em;
    height: 8em;
}

.yt-loader_container > svg {
    width: 100%;
    height: 100%;
}

/* 
* === Circular loader ends
 */

 /* 
 * ==== Erro section
  */

.yt-error-container {
    padding: 15px 5px;
}

.yt-error-title {
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 700;
    padding: 5px;
}

.yt-error-content {
    padding: 10px 2px
}

.yt-error-content_list {
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
}

.yt-error-btns {
    padding: 10px 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.yt-error-btns > button {
    width: 150px;
    margin: 5px;
    height: 50px;
    border: 1px solid;
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
    background-color: inherit;
}

.yt-error-btns > button:hover {
    cursor: pointer;
}

.yt-error-btns > button > a:hover {
    background-color: inherit;
}

.yt-error-btns > button > a {
    padding: 12px 2px;
    color: #000;
    font-size: 14px;
    font-family: 'Assistant', sans-serif;
    font-weight: 500;
}

/* 
* Categories styles
 */

.yt-categories_container {
    width: 100%;
    padding: 50px 5px;
}

.yt-categories_title {
    font-size: 18px;
    font-family: 'Asisstant', sans-serif;
    font-weight: 700;
}

.yt-categories_list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px 0px;
}

.yt-categories_btn {
    width: 100px;
    height: 50px;
    margin: 5px;
    background-color: #fff;
    font-size: 16px;
    font-family: 'Asisstant', sans-serif;
    font-weight: 500;
    border: 1px solid #000;
}

.yt-categories_btn:hover {
    cursor: pointer;
    background-color: rgb(233, 233, 233);
}

/* Alert styles */
.yt-error-alert_row {
    position: absolute;
    top: 45%;
    right: 0%;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
}

.yt-error-alert {
    width: auto;
    padding: 4px 15px;
    background-color: #000;
    color: #fff;
    border-radius: 0px;
    display: flex;
    align-items: center;

}

.yt-error-alert > div {
    font-size: 12px;
    font-weight: 500;
    font-family: Assistant, sans-serif !important;
    text-align: left;
    flex: 1;
    padding-left: 5px;
}

.yt-error-alert > button {
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.yt-error-alert > button:hover {
    cursor: pointer;
}

.yt-error-alert > img {
    width: 18px;
    height: auto;
    border: none;
}

/* @media (min-width: 375px) {
    .yt-error-alert {
        top: -5%;
        left: 5%;
        right: 5%;
    } 
}

@media (min-width: 900px) {
    .yt-error-alert {
        top: -7%; 
        left: 20%;
        right: 20%;
    } 
} */
</style>
