<script setup lang="ts">
import {ref, computed, onMounted, reactive, type Ref} from 'vue';
import type { Answer, QuizAnswer } from '../utils/types';
import QuizTooltip from './QuizTooltip.vue';

// define events
const emit = defineEmits(['updateAnswer'])

interface SelectChoice {
    id: string | number
}

const props = defineProps<{
    count: number | null,
    multiple: boolean,
    unlimited: boolean,
    answers: Answer[],
    questionId: string,
    slug: string,
    choices: QuizAnswer[]
}>();

const answersWithImages = computed(() => Boolean(props.choices[0]?.image || false));

 let _currentChoices: Ref<string[] | string> = ref([]);
onMounted(() => {
    const _choices: string[] | string = props.answers.find(({id}) => id === props.questionId)?.answers || [];
    _currentChoices.value = _choices;
    emit('updateAnswer', {ready: isReady(), choices: _choices})
});

const isReady = () => {
    if (props.multiple) {
        return props.unlimited
          ? _currentChoices.value.length > 0
          : _currentChoices.value.length === props.count;
      } else {
        return _currentChoices.value.length === 1;
      }
};

const selectChoice = ({id}: SelectChoice) => {
  const {count, unlimited} = props;
  const currentChoices = [..._currentChoices.value];
  // if currentChoices are empty add and return
  if (currentChoices.length === 0) {
    currentChoices.push(id as string);
    _currentChoices.value = [...currentChoices]
    emit('updateAnswer', {ready: isReady(), choices: currentChoices})
    return;
  }

  // Remove if existing
  const _choiceIdx = currentChoices.findIndex((item) => item === id);
  if (_choiceIdx >= 0) {
    currentChoices.splice(_choiceIdx, 1);
    _currentChoices.value = [...currentChoices]
    emit('updateAnswer', {ready: isReady(), choices: currentChoices})
    return;
  }

  if (count === 1) {
    currentChoices.splice(_choiceIdx, 1, id as string);
    _currentChoices.value = [...currentChoices]
    emit('updateAnswer', {ready: isReady(), choices: currentChoices})
    return
  }

  if ((count && currentChoices.length !== count) || unlimited) {
    currentChoices.push(id as string);
    _currentChoices.value = [...currentChoices]
    emit('updateAnswer', {ready: isReady(), choices: currentChoices})
    return
  }
}

</script>

<template>
    <div class="yt-choice-container">
        <div class="yt-choice-row" v-if="answersWithImages">
            <div
             class="yt-choice-btn__image-container"
             v-for="{id, text, description, image} in choices" :key="id"
             @click="selectChoice({id})"
            >
                <div
                class="yt-choice-btn__image-top"
                :class="[_currentChoices.includes(id) ? 'yt-choice-btn-active' : '']"
                >
                    <img :src="image" :style="{width: slug === 'MQ7' ? '70%' : '50px'}" v-if="image" />
                    <div class="span" v-if="description">
                      <div class="yt-tooltip">
                        <img class="yt-tooltip_img" src="../assets/info.svg" />
                        <span class="yt-tooltiptext">{{ description }}</span>
                      </div>
                    </div>
                </div>
                <div
                class="yt-choice-btn__image-bottom"
                >
                    <span>{{ text }}</span>
                    <div class="yt-tooltip" v-if="description">
                      <img class="yt-tooltip_img" src="../assets/info.svg" />
                      <span class="yt-tooltiptext">{{ description }}</span>
                    </div>
                    <!-- <QuizTooltip
                        :label="text" 
                        :description="description"
                        v-if="description"
                    /> -->
                </div>
            </div>
        </div>
        <div class="yt-choice-row" v-else>
            <button
             class="yt-choice-btn"
            :class="[_currentChoices.includes(id) ? 'yt-choice-btn-active' : '']"
             v-for="{id, text, description} in choices" :key="id"
             @click="selectChoice({id})"
            >
              <span>{{text}}</span>
              <!-- <QuizTooltip 
                :label="text" 
                :description="description"
                v-if="description"
              /> -->
              <div class="yt-tooltip" v-if="description">
                <img class="yt-tooltip_img" src="../assets/info.svg" />
                <span class="yt-tooltiptext">{{ description }}</span>
              </div>
            </button>
        </div>
    </div>
</template>