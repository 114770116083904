import axios from 'axios';

const DSML_AUTHORIZATION_TOKEN = 'Token 199b59b2ca05013b507c1ca0aab4ebfbeee45a8f';

const getIngredients = async (query: any) => {
  if (!query) return [];
  const elasticsearchUrl = 'https://gcp-dsml-api.yuty.me/search_allergen';
  const url = `${elasticsearchUrl}?query=${query}`;

  try {
    const result = await axios.get(url, { headers: {} });
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const getCities = async (query: any) => {
  if (!query) return [];
  const elasticsearchUrl = 'https://gcp-dsml-api.yuty.me/search_city';
  const url = `${elasticsearchUrl}?query=${query}`;

  try {
    const result = await axios.get(url, { headers: {} });
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export default { getIngredients, getCities }
