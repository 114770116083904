/* eslint-disable camelcase */

import type { AnswerRaw, QuestionInclude, QuestionRaw, QuizAnswer, QuizQuestion } from '../types';

const imageTextSwitch = (text: string) => {
  let name = '';
  switch (text) {
    case "Body Makeup":
      name = "blusher.png";
      break
    case "Brows":
      name = "brow-liner.png";
      break
    case "Complexion":
      name = "foundation.png";
      break
    case "Eyes":
      name = "mascara.png"
      break
    case "Lips":
      name = "lipglos.png"
      break
  }

  return name;
}

export const addImagesToCategories = (answers: QuizAnswer[]) => {
  return answers.map((answer) => ({
    ...answer,
    image: `https://yutybazar-project.gitlab.io/yuty-widgets/assets/${imageTextSwitch(answer.text)}`
  }))
}

export const removeNailsAndMakeupRemover = (questions: QuizQuestion[]) => {
  const categoryIdx = questions.findIndex(({slug}) => slug === "MQ7");
  if (categoryIdx < 0) return questions;
  let _answers = questions[categoryIdx].answers;
  _answers = _answers.filter((answer) => answer.text !== 'Setting Spray & Makeup Remover' && answer.text !== 'Nails');
  _answers = addImagesToCategories(_answers)
  questions[categoryIdx].answers = _answers;
  return questions;
}

export const removePhthalatesAndOrganic = (questions: QuizQuestion[]): QuizQuestion[] => {
  const categoryIdx = questions.findIndex(({slug}) => slug === "MQ14");
  if (categoryIdx < 0) return questions;
  let _answers = questions[categoryIdx].answers;
  _answers = _answers.filter((answer) => answer.text !== "Free from Phthalates" && answer.text !== "Organic");
  questions[categoryIdx].answers = _answers;
  return questions;
}

const deserializeAnswers = (answers: AnswerRaw[]): QuizAnswer[] =>
  answers.map((answer) => ({
    id: answer.id,
    text: answer.attributes.text,
    position: answer.attributes.position,
    description: answer.attributes.description,
    image: answer.attributes.image
  }));

const extractAnswers = (question: QuestionRaw, included: QuestionInclude[]) => {
  const answerIds = question.relationships['answer-options'].data.length ? question.relationships['answer-options'].data.map((e) => e.id) : [];
  return included.filter((e) => answerIds.includes(e.id));
};

export const deserializeQuestions = (data: QuestionRaw[], included: QuestionInclude[], storeName: string): QuizQuestion[] => {
  let questions: QuizQuestion[] = data.map((question) => ({
    id: question.id,
    text: question.attributes.text,
    position: question.attributes.position,
    multipleAnswer: question.attributes.multiple_answer || question.attributes['multiple-answer'],
    answersCount: question.attributes.answers_count || question.attributes['answers-count'],
    unlimited: question.attributes.unlimited,
    additionalInfo: question.attributes.extra_info || question.attributes['extra-info'],
    freetext: question.attributes.freetext,
    slug: question.attributes.slug,
    isAllergen: question.attributes.is_alergen || question.attributes['is-alergen'],
    isCity: question.attributes.is_city || question.attributes['is-city'],
    answers: deserializeAnswers(extractAnswers(question, included))
  }));

  questions = storeName === 'colorblend-makeup-london' ? removeNailsAndMakeupRemover(questions) : questions;
  questions = 'colorblend-makeup-london' ? removePhthalatesAndOrganic(questions) : questions;

  return questions;
};

export const deserializeQuestionsV2 = (data: any[], storeName: string) => {
  let answer_options_ids: number[] = []
  let questions: QuizQuestion[] = data.map((question) => {
    const {answers, answerOptionsIds} = deserializeAnswersV2(question.answer_options)
    answer_options_ids = [...answer_options_ids, ...answerOptionsIds]

    return {
      id: question.id,
      text: question.text,
      position: question.position,
      multipleAnswer: question.multiple_answer,
      answersCount: question.answers_count,
      unlimited: question.unlimited,
      additionalInfo: question.extra_info,
      freetext: question.freetext,
      slug: question.slug,
      isAllergen: question.is_alergen,
      isCity: question.is_city,
      answers
    }
  });

  questions = storeName === 'colorblend-makeup-london' ? removeNailsAndMakeupRemover(questions) : questions;
  questions = 'colorblend-makeup-london' ? removePhthalatesAndOrganic(questions) : questions;

  return {questions, answer_options_ids};
};

const deserializeAnswersV2 = (answers: any[]) => {
  const answerOptionsIds: number[] = []
  const answers_ = answers.map((answer) => {

    answerOptionsIds.push(answer.id)
    
    return {
        id: answer.id,
        text: answer.text,
        position: answer.position,
        description: answer.description,
        image: answer.image
      }
    }
  );

  return {answers: answers_,  answerOptionsIds}
 }


export const serializeAnswerOptionsIds = (data: any[]) => {
  const options = data.reduce((acc, option) => {
    return {
      ...acc, 
      ...(!!option.attributes.image && {[option.id]: option.attributes.image})
    }
  }, {});

  return options
}