<script setup lang="ts">
import type { Answer } from '@/utils/types';
import {ref, onMounted, type Ref} from 'vue';

// define events
const emit = defineEmits(['updateAnswer'])

const props = defineProps<{
    answers: Answer[],
    questionId: string,
}>();

const freetext: Ref<string> = ref('');

 const handleInput = () => {
     emit('updateAnswer', {ready: freetext.value.length > 0, choices: [freetext.value]})
}

onMounted(() => {
    const _freetext = props.answers.find(({id}) => id === props.questionId)?.answers || '';
    freetext.value = _freetext.length ? _freetext as string : ''; 
    emit('updateAnswer', {ready: freetext.value.length > 0, choices: freetext.value})
})
</script>

<template>
    <div class="quiz-freetext">
      <div>
        <span class="quiz-freetext__title">Type below:</span>
        <textarea
          class="quiz-freetext__textarea"
          v-model="freetext"
          @input="handleInput()"
        />
      </div>
    </div>
</template>