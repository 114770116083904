<script setup lang="ts">
import { computed, reactive, ref, onMounted } from 'vue';
import AnswerSelect from './AnswerSelect.vue';
import FreeText from './FreeText.vue';
import CitySelect from './CitySelect.vue';
import AllergenSelect from './AllergenSelect.vue'
import type { Answer, QuizQuestion } from '../utils/types';

interface SelectFreetextEvent {
    ready: boolean,
    choices: [] | ''
}

interface NavEvent {
    type: 'prev' | 'next'
}

// define events
const emit = defineEmits(['onready', 'getcamera'])

const props = defineProps<{
    answers: Answer[],
    questions: QuizQuestion[],
    cameraPresence: boolean
}>(); 

let currentQuestion = reactive<QuizQuestion>({
    id: '',
    text: '',
    position: 0,
    multipleAnswer: false,
    answersCount: null,
    unlimited: false,
    freetext: false,
    isAllergen: false,
    isCity: false,
    slug: '',
    answers: []
});
const questionId = ref('')

const currentQuestionPosition = ref(0);
const noOfQuestions = ref(10);
const isFreetext = ref(false);
const isCity = ref(false);
const isAllergenQuestion = ref(false);

const isNextEnabled = ref(false)
const isBackEnabled = ref(false)

const progressBarSize = computed(() => {
    return (currentQuestionPosition.value * 100) / noOfQuestions.value
});

const _answers = ref<Answer[]>([]);

onMounted(() => {
    const idx = props.cameraPresence ? 1 : 0;
    currentQuestionPosition.value = idx;
    currentQuestion = {
        ...props.questions[idx]
    };
    questionId.value = currentQuestion.id;
    _answers.value = [...props.answers];
    isFreetext.value = currentQuestion.freetext && !currentQuestion.isCity;
    isCity.value = currentQuestion.isCity;
    isAllergenQuestion.value = currentQuestion.isAllergen;
    noOfQuestions.value = props.questions.length
});

const handleAllergenSelect = ({ready, choices}: SelectFreetextEvent) => {
    const currentQuizIdx = _answers.value.findIndex(answer => answer.id == currentQuestion.id);
    if (currentQuizIdx >= 0) {
        const _answer = {..._answers.value[currentQuizIdx]};
        _answer.answers = choices;
        _answers.value[currentQuizIdx] = _answer
    } else {
        const _answer: Answer = {
            id: currentQuestion.id,
            type: 'makeup',
            isImage: false,
            isFreetext: currentQuestion.freetext,
            answers: choices
        }

        _answers.value.push(_answer);
    }
    isNextEnabled.value = ready;

}

const handleSelectedFreetextAnswers = ({ready, choices}: SelectFreetextEvent) => {
    const currentQuizIdx = _answers.value.findIndex(answer => answer.id == currentQuestion.id);
    if (currentQuizIdx >= 0) {
        const _answer = {..._answers.value[currentQuizIdx]};
        _answer.answers = choices;
        _answers.value[currentQuizIdx] = _answer
    } else {
        const _answer: Answer = {
            id: currentQuestion.id,
            type: 'makeup',
            isImage: false,
            isFreetext: currentQuestion.freetext,
            answers: choices
        }

        _answers.value.push(_answer);
    }
    isNextEnabled.value = ready;
}

const handleNav = ({type}: NavEvent) => {
  if (!isNextEnabled.value && type === 'next') return;

    const current = currentQuestionPosition.value;
  // Back to first page
  if (type === 'prev' && !isBackEnabled.value) {
    // emit('getcamera')
    return;
  }

  const questions = [...props.questions];

  // Back to previuos question
  if (type === 'prev') {
    currentQuestion = {...questions[current - 1]};
    questionId.value = currentQuestion.id;
    currentQuestionPosition.value = current - 1;
    isFreetext.value = currentQuestion.freetext && !currentQuestion.isCity;
    isCity.value = currentQuestion.isCity;
    isAllergenQuestion.value = currentQuestion.isAllergen;
    isBackEnabled.value = (currentQuestionPosition.value > 1 && props.cameraPresence) || (currentQuestionPosition.value > 0 && !props.cameraPresence) 

    return;
  }

  // Navigate to next question
  if (type === 'next' && questions.length !== current + 1) {
    currentQuestion = {...questions[current + 1]};
    questionId.value = currentQuestion.id;
    currentQuestionPosition.value = current + 1;
    isFreetext.value = currentQuestion.freetext && !currentQuestion.isCity;
    isCity.value = currentQuestion.isCity;
    isAllergenQuestion.value = currentQuestion.isAllergen;
    isBackEnabled.value = (currentQuestionPosition.value > 1 && props.cameraPresence) || (currentQuestionPosition.value > 0 && !props.cameraPresence) 

    return;
  }

  // Get recoomendation and navigate to recommendation page
  if (type === 'next' && questions.length === current + 1) {
    // Add/fetch recommended products
    emit('onready', {_answers: _answers.value})

    return;
  }
}

</script>

<template>
    <div class="yt-questions-container">
        <div class="yt-question-body">
            <!-- progress bar -->
            <div class="yt-progress-container">
                <div class="yt-progress-info">Question {{currentQuestionPosition}} / {{noOfQuestions}}</div>
                <div class="yt-progress-cont">
                    <div class="yt-progress-bar" :style="{width: progressBarSize + '%'}"></div>
                </div>
            </div>
            <!-- question title -->
            <div class="yt-question-title">{{currentQuestion?.text}}</div>

            <!-- <div class="yt-quiz-choices_container"> -->
            <!-- choices container -->
            <AllergenSelect
                v-if="isAllergenQuestion"
                :questionId="questionId"
                :answers="currentQuestion.answers"
                :userAnswers="_answers"
                @select="handleAllergenSelect"
            />
            <FreeText 
                :answers="_answers"
                :questionId="questionId" 
                @updateAnswer="handleSelectedFreetextAnswers"
                v-else-if="isFreetext"
                :key="questionId+'text'"
            />
            <CitySelect 
                :questionId="questionId"
                :answers="currentQuestion.answers"
                :userAnswers="_answers"
                @select="handleAllergenSelect"
                v-else-if="isCity"
                :key="questionId+'city'"
            />
            <AnswerSelect 
                :count="currentQuestion.answersCount" 
                :multiple="currentQuestion.multipleAnswer"
                :unlimited="currentQuestion.unlimited"
                :answers="_answers"
                :questionId="questionId" 
                :slug="currentQuestion.slug"
                :choices="currentQuestion.answers"
                @updateAnswer="handleSelectedFreetextAnswers"
                v-else
                :key="questionId+'select'"
            />
        </div>

        <!-- navigation container -->
        <div class="yt-nav-container">
            <button class="yt-nav-btn" :class="[!isBackEnabled ? 'yt-nav-btn-disabled' : '']" @click="handleNav({type: 'prev'})">
                <img alt="Nav icon" class="yt-nav-icon-left" src="../assets/chevron-left.svg" />
                Back
            </button>
            <button class="yt-nav-btn" :class="[!isNextEnabled ? 'yt-nav-btn-disabled' : '']" @click="handleNav({type: 'next'})">
                Next
                <img alt="Nav icon" class="yt-nav-icon-right"  src="../assets/chevron-right.svg" v-if="isNextEnabled" />
                <img alt="Nav icon" class="yt-nav-icon-right"  src="../assets/chevron-right-gray.svg" v-else />
            </button>

        </div>
    </div>
</template>