<template>
  <div class="quiz-multiselect">
    <div v-if="answers.length > 0" class="quiz-multiselect__answers">
      <div
        class="quiz-multiselect__answer"
        v-for="answer in answers"
        :key="answer.id"
        :class="{ 'quiz-multiselect__answer--selected': selectedAnswer === answer }"
        @click="() => handleClick(answer)"
      >
        <span>{{ answer.text }}</span>
      </div>
    </div>
    <div v-if="answers.length === 0 || (selectedAnswer && selectedAnswer.text === 'Yes')" style="text-align: left;">
      <span class="quiz-multiselect__title">Type below:</span>
      <Multiselect
        class="allergen-mutliselect"
        open-direction="bottom"
        v-model="selectedAnswers"
        placeholder=""
        :options="options"
        :create-option="true"
        :searchable="true"
        :loading="isLoading"
        :clear-on-select="true"
        :close-on-select="true"
        :caret="false"
        :canClear="false"
        :hide-selected="true"
        :show-labels="false"
        @search-change="findOptions"
        @select="handleInput"
      >
      </Multiselect>
    </div>
  </div>
</template>

<script>
import { getCities } from '../utils/api';
import { ref, onMounted } from 'vue';
import Multiselect from '@vueform/multiselect';
import _ from 'lodash';

export default {
  props: {
    questionId: {
      type: String,
      default: undefined
    },
    answers: {
      type: Array,
      required: true
    },
    userAnswers: {
        type: Array,
        required: true
    }
  },
  components: {
    Multiselect
  },
  setup(props, { root, emit }) {
    const options = ref([]);
    const selectedAnswer = ref(null);
    const selectedAnswers = ref('');
    const isLoading = ref(false);

    const findOptions = _.debounce(async (query) => {
      isLoading.value = true;

      try {
        options.value = await getCities(query);
        if (query) options.value.push(query);
      } catch (e) {
        console.error(e);
      } finally {
        isLoading.value = false;
      }
    }, 100);

    const handleInput = () => {
      const answers = selectedAnswers.value;
      emit('select', {choices: answers, ready: answers.length});
    };

    onMounted(() => {
      const userAnswer = props.userAnswers.find((e) => e.id === props.questionId);

      if (!userAnswer || userAnswer.answers === '-') {
        selectedAnswers.value = '';
        selectedAnswer.value = props.answers.find((e) => e.text === 'No');
        emit('select', {choices: '', ready: false});
      } else {
        const answers = userAnswer.answers;
        selectedAnswers.value = answers;
        selectedAnswer.value = props.answers.find((e) => e.text === 'Yes');
        emit('select', {choices:answers, ready: selectedAnswers.value.length ? true : false});
      }
    });

    const handleClick = (answer) => {
      selectedAnswer.value = answer;
      if (answer.text === 'Yes') {
        emit('select', {choices: selectedAnswers.value ? selectedAnswers.value.length : '-', ready: selectedAnswers.value ? true : false});
      } else {
        emit('select', {choices: '-', ready: true});
      }
    };

    return {
      options,
      selectedAnswer,
      selectedAnswers,
      isLoading,
      findOptions,
      handleInput,
      handleClick
    };
  }
};
</script>
