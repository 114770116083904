import { defineCustomElement } from 'vue'
import YutyWidget from './components/WidgetContainer.ce.vue'

// convert into custom element constructor
const YutyElement = defineCustomElement(YutyWidget)


// register
customElements.define('yuty-widget', YutyElement)


// import { createApp } from 'vue'
// import App from './App.vue'

// import './assets/main.css'

// createApp(App).mount('#app')