import advisor from "./advisor";
import helpers from "./helpers";
import products from "./products";
import questions from "./questions";
import quiz from "./quiz";
import shopify from "./shopify";
import wishlist from "./wishlist";
import clients from "./clients";

export const getReco = advisor.getReco;
export const imageUpload = advisor.imageUpload;
export const getIngredients = helpers.getIngredients;
export const getCities = helpers.getCities;
export const getRecommendedProducts = products.getRecommendedProducts;
export const getQuestions = questions.getQuestions;
export const getAnswerOptions = questions.getAnswerOptions;
export const createQuiz = quiz.createQuiz;
export const updateAnswer = quiz.updateAnswer;
export const getStoreProducts = shopify.getProducts;
export const getCart = shopify.getCart;
export const addToCart = shopify.addToCart;
export const updateCart = shopify.updateCart;
export const createWishlist = wishlist.createWishlist;
export const getClientDetails = clients.getClientDetails;
