<script>
import {createWishlist, addToCart, updateCart, getCart} from "@/utils/api";
import { getProductImage, getProductCoverImage, getActives, getFunctions, getMatchScore } from "../utils/getters/products";
import { computed, ref, watch, onMounted, reactive, defineComponent } from 'vue';
import debounce from 'lodash.debounce'
import Loader from './CircularLoader.vue';

/*
* FaceMesh global variables
*/

let faceMesh = window.FaceMesh ? new window.FaceMesh({
    locateFile: (file) => {
      return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
    },
  }) : null;

export default defineComponent({
    components: {},
    props: {
        products: {
            type: Array,
            default: [],
        },
        recommendationDetails: {
            type: Object,
            default: {}
        },
        analysisObj: {
            type: Object,
            default: {}
        },
        isRecoAnalysis: {
            type: Boolean,
            default: false
        },
        concernDesc: {
            type: Object,
            default: null,
        },
        preferences: {
            type: Array,
            default: [],
        },
        clientId: {
            type: Number,
            default: null,
        },
        quizId: {
            type: String,
            default: null,
        },
        quizType: {
            type: String,
            default: 'makeup'
        },
        isFileUpload: {
            type: Boolean,
            default: false
        },
        cartObj: {
            type: Object,
            default: {}
        }
    },
    setup(props, { emit }) {
        const selectedMatchItemIndex = ref(0);
        const setSelectedMatchItemIndex = ((idx) => selectedMatchItemIndex.value = idx);
        const recommendedProducts = ref([]);
        const selectedMatchItem = computed(() =>recommendedProducts.value[selectedMatchItemIndex.value]);
        const actives = ref([]);
        const functions_ = ref([]);
        const productDesc = ref('');
        const loading = ref(true);
        const cartQuntityObj = reactive({});
        const cartObj = ref({});
        const errorMessage = ref('');
        const isErrorAlert = ref(false);
        const isSuccess = ref(false);
        const successMsg = ref('');
        const wishedCount = ref(0);
        const hasWishlist = ref(false);

        const imageElement = ref(null);
        const canvas = ref(null);
        const canvasSizeY = ref(370);
        const canvasSizeX = ref(370);

        watch(selectedMatchItemIndex, (newValue, oldValue) => {
            const _product = recommendedProducts.value[newValue].product
            productDesc.value = _product.body_html;
            let _actives = getActives(_product.handle, props.recommendationDetails.reco) || [];
            let _functions = getFunctions(_product.handle, props.recommendationDetails.reco) || [];
            actives.value = [..._actives];
            functions_.value = [..._functions];
        })

        const analysisStats = ref([]);
        const isWishing = ref(false);
        const isSavingWishedProducts = ref(false);
        const isWished = ref(false);
        const email = ref('');
        const emailValid = ref(true);
        const cartCount = ref(0);

        const isMatch = ref(true);

        const validateEmail = () => {
        return !!email.value
            .toLowerCase()
            .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        };

        const updateWishedCount = () => {
            const count = recommendedProducts.value.reduce((acc, item) => {
                if (item.isfavourite) {
                    return acc + 1;
                }
                return acc
            }, 0)

            wishedCount.value = count;
        }

        const toggleWishedProduct = () => {
            const product_ = recommendedProducts.value[selectedMatchItemIndex.value];
            product_.isfavourite = !product_.isfavourite
            recommendedProducts.value[selectedMatchItemIndex.value] = {...product_}
            isWishing.value = true;
            isWished.value = false;
            updateWishedCount()
        }

        const updateFavourites = () => {
            const products_ = recommendedProducts.value.map((product) => ({...product, isfavourite: true}));
            recommendedProducts.value = [...products_];
            wishedCount.value = products_.length
        }

        const handleWishButton = () => {
            if (isWishing.value) {
                const _valid = validateEmail();
                emailValid.value = _valid;
                if (_valid) {
                  addToWishlist();
                }
            } else {
                isWishing.value = true;
            }
        }

        const addToWishlist = async () => {
            try {
                let products_ = recommendedProducts.value.filter(({isfavourite}) => isfavourite);
                products_ = products_.length > 0 ? products_ : recommendedProducts.value;
                products_ = products_.map(({product}) => ({name: product.title, slug: product.handle}))
                if (props.quizId) {
                  await createWishlist(products_, email.value, props.clientId, props.quizId);
                }
                if (wishedCount.value === 0 && !hasWishlist.value) {
                    updateFavourites()
                }
                isWished.value = true;
                isSavingWishedProducts.value = false;
                isSuccess.value = true;
                successMsg.value = 'Wishlist saved successfully';
                hasWishlist.value = true;
                timeOutAlert();
            } catch (error) {
                isSavingWishedProducts.value = false;
                errorMessage.value = 'Failed to add to wishlist. Please try again!';
                isErrorAlert.value = true;
                isSuccess.value = false;
                successMsg.value = ''
                timeOutAlert()
            }
        }

        onMounted(() => {

            cartObj.value =  {...cartObj.value, ...props.cartObj}
            _addToCart(props.products)
            const _reco = [...props.products.map((product) => {
                const _variant = product.variant;
                return {
                    quantity: _variant.inventory_quantity > 0 ? 1 : 0,
                    isfavourite: false,
                    product
                }
            })];
            recommendedProducts.value = _reco;
            const _concerns = props.analysisObj?.skin_concerns_colours || {};
            const _concernsKeys = Object.keys(_concerns) || [];
            const _concernsArrays = _concernsKeys.map((key) => {
                const _concern = _concerns[key];
                const obj = {
                    value: 35,
                    color: `rgb(${_concern[0]},${_concern[1]},${_concern[2]})`,
                    text: key
                }
                return obj;
            });
            analysisStats.value = [..._concernsArrays]
            productDesc.value = recommendedProducts.value[0]?.product.body_html || '';
            if (props.isRecoAnalysis) {
                isMatch.value = false
            }

            const count = props.products.filter(({variant}) => variant.inventory_quantity > 0).length;
            cartCount.value = count;
            loading.value = false;

            if (props.isFileUpload) {
                canvasSizeX.value = 300
            }
            if (props.quizType === 'skin_care') initializeFaceMesh();
        })

        const handleTab = (e) => {
            const id = e?.target?.id;
            isMatch.value = id === 'matches';
        }

        const _addToCart = async (products) => {

            try {
                if (products.length > 0) {
                    const filterProducts = products.filter((product) => !cartObj.value[product.variant.id]);
                    const res = await addToCart(filterProducts);

                    const cart = res.items.reduce((acc, item) => {
                        return {...acc, [item.variant_id]: item.key}
                    }, {})

                    cartObj.value = {...cartObj.value, ...cart};
                }
            } catch (e) {
                console.log('failed to add to cart')
                console.error(e);
            }
        }

        const adjustQuantity = async ({index, type}) => {
            const product_ = recommendedProducts.value[index]
            const _variant = product_.product.variant
            if (_variant.inventory_quantity < 1) return;
            const _cartObj = {...cartObj.value}

            let q = 0;

            if (type === 'add') {
                Object.assign(cartQuntityObj, {
                   [_variant.id]: cartQuntityObj[_variant.id] ? cartQuntityObj[_variant.id] + 1 : 1
                })
                q = product_.quantity + 1
                recommendedProducts.value[index].quantity = q;
                // Check if the variant exist in the cartObj
                // If exist call _updateCart to update item in the cart
                // Else add item to the cart
                _cartObj[_variant.id] ? await _updateCart(_cartObj[_variant.id], q, _variant.id, index, type) : await _addItemToCart(_variant, q, _variant.id, index);
            } else {
                Object.assign(cartQuntityObj, {
                   [_variant.id]: cartQuntityObj[_variant.id] ? cartQuntityObj[_variant.id] - 1 : 1
                })
                q = product_.quantity - 1;
                recommendedProducts.value[index].quantity = q;
                await _updateCart(_cartObj[_variant.id], q, _variant.id, index, type)
            }
        }

        const _updateCart = debounce(async (key, quantity, variantId, index, type) => {
            try {
                await updateCart(key, quantity)

                Object.assign(cartQuntityObj, {
                    [variantId]: 0
                })
                // If the item quantity is 0 remove the item variant from the cartObj
                if (quantity === 0) {
                    cartObj.value = {...cartObj.value, [variantId]: undefined}
                }
                return 1;
            } catch (e) {
                if (type === 'add') {
                    recommendedProducts.value[index].quantity = quantity - cartQuntityObj[variantId];
                } else {
                    recommendedProducts.value[index].quantity = quantity + cartQuntityObj[variantId];
                }
                errorMessage.value = e;
                isErrorAlert.value = true;
                timeOutAlert();
                console.log('ee', e)
                return 0;
            }
        }, 500)

        const _addItemToCart = debounce(async (variant, quantity, variantId, index) => {
            try {
                const items = [{
                    variant: variant,
                    quantity
                }]
                const res = await addToCart(items);

                const cart = res.items.reduce((acc, item) => {
                    return {...acc, [item.variant_id]: item.key}
                }, {})

                cartObj.value = {...cartObj.value, ...cart}

                Object.assign(cartQuntityObj, {
                    [variantId]: 0
                })
                return 1;
            } catch (e) {
                recommendedProducts.value[index].quantity = 0;
                errorMessage.value = e;
                isErrorAlert.value = true;
                timeOutAlert()
                console.log('eee', e)
                return 0;
            }
        }, 500)

        const closeAlert = () => {
            errorMessage.value = '';
            isErrorAlert.value = false;
        }

        const timeOutAlert = () => {

            setTimeout(function () {
                errorMessage.value = '';
                isErrorAlert.value = false;
                successMsg.value = '';
                isSuccess.value = false;
            }, 2000);
        }

        const initializeFaceMesh = () => {
            if (!faceMesh) {
                faceMesh = window.FaceMesh ? new window.FaceMesh({
                    locateFile: (file) => {
                    return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
                    },
                }) : null;

                if (!faceMesh) return;
            }

            faceMesh.setOptions({
                maxNumFaces: 1,
                minDetectionConfidence: 0.5,
                minTrackingConfidence: 0.5
            });
            faceMesh.onResults(onResults);
            render();
        }

        const onResults = (results) => {
          const canvasElement = canvas.value;
          const canvasCtx = canvasElement.getContext('2d');

          canvasCtx.save();
          canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

          canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);

          if (results.multiFaceLandmarks) {
            for (const landmarks of results.multiFaceLandmarks) {              
              // Define the areas you want to highlight
              // Smooth lines custom function
              const forehead_smooth = [103, 104, 105, 66, 107, 9, 336, 296, 334, 333, 332, 297, 338, 10, 109, 67, 103]
              const nose_mouth_smooth = [221, 190, 243, 231, 229, 226, 156, 116, 123, 147, 213, 135, 216, 206, 203, 129, 102, 48, 218, 438, 278, 331, 358, 423, 426, 436, 364, 433, 376, 352, 345, 383, 446, 449, 451, 463, 414, 441, 221]

              drawCurve(forehead_smooth, landmarks);
              drawCurve(nose_mouth_smooth, landmarks);
            }
          }
          canvasCtx.restore();
        }

        // Function to draw the custom curves
        const drawCurve = (points, landmarks) => {
            const canvasCtx = canvas.value.getContext('2d');
            canvasCtx.beginPath();
            canvasCtx.moveTo(landmarks[points[0]].x * canvasSizeX.value, landmarks[points[0]].y * canvasSizeY.value);

            const numPoints = points.length;
            for (let i = 1; i < numPoints; i++) {
                const currentPoint = points[i];
                const nextPoint = points[(i + 1) % numPoints]; // Wrap around to the first point for the last point

                const cpx = ((landmarks[currentPoint].x * canvasSizeX.value) + (landmarks[nextPoint].x * canvasSizeX.value)) / 2;
                const cpy = ((landmarks[currentPoint].y * canvasSizeY.value) + (landmarks[nextPoint].y * canvasSizeY.value)) / 2;

                canvasCtx.quadraticCurveTo(landmarks[currentPoint].x * canvasSizeX.value, landmarks[currentPoint].y * canvasSizeY.value, cpx, cpy);
            }

            // Connect the last point to the first point with a quadratic curve
            const cpx = ((landmarks[points[0]].x * canvasSizeX.value) + (landmarks[points[points.length - 1]].x * canvasSizeX.value)) / 2;
            const cpy = ((landmarks[points[0]].y * canvasSizeY.value) + (landmarks[points[points.length - 1]].y * canvasSizeY.value)) / 2;

            canvasCtx.quadraticCurveTo(landmarks[points[0]].x * canvasSizeX.value, landmarks[points[0]].y * canvasSizeY.value, cpx, cpy);

            // Set the line style and stroke the path
            canvasCtx.lineWidth = 2;
            canvasCtx.strokeStyle = "rgb(200,152,210)";
            canvasCtx.closePath(); // Add this to close the path
            canvasCtx.stroke();
        }

        const render = async () => {
           try {
              await faceMesh.send({ image: imageElement.value })
           } catch (error) {
              console.log('====error====', error)
           }
           
        }

        return {
            selectedMatchItemIndex,
            setSelectedMatchItemIndex,
            recommendedProducts,
            selectedMatchItem,
            actives,
            functions_,
            productDesc,
            loading,
            imageElement,
            canvas,
            canvasSizeY,
            canvasSizeX,
            analysisStats,
            isWishing,
            isSavingWishedProducts,
            isWished,
            hasWishlist,
            wishedCount,
            email,
            emailValid,
            cartCount,
            isMatch,
            isSuccess,
            successMsg,
            isErrorAlert,
            errorMessage,
            closeAlert,
            handleWishButton,
            toggleWishedProduct,
            handleTab,
            adjustQuantity,
            initializeFaceMesh,
            emit,
            getProductImage,
            getProductCoverImage,
            getActives,
            getFunctions,
            getMatchScore
        }
    }
})



</script>

<template>
    <Loader v-if="loading"/>
    <div class="yt-recommendation-container" v-else>
        <div class="yt-error-alert_row" v-if="isErrorAlert">
            <div class="yt-error-alert">
                <img alt="Close Icon" src="../assets/error.svg" />
                <div>{{ errorMessage }}</div>
            </div>
        </div>
        <div class="yt-error-alert_row" v-if="isSuccess">
            <div class="yt-error-alert">
                <img alt="Close Icon" src="../assets/heart-white.svg" />
                <div>{{ successMsg }}</div>
            </div>
        </div>
        <div class="yt-reco-tab_nav">
            <button
             id="matches"
             :style="{borderBottom: isMatch ? '2px solid #000000' :'2px solid #929471', color: isMatch ? '#000000' :'#929471' }"
             @click="handleTab"
             >
             MATCHES
            </button>
            <button
             id="analysis"
             :style="{borderBottom: isMatch ? '2px solid #929471' :'2px solid #000000', color: isMatch ? '#929471' :'#000000' }"
             @click="handleTab"
             >
             ANALYSIS
            </button>
        </div>
        <div v-if="isMatch">
            <!-- list container -->
            <div class="yt-recommendation-section">
                <div class="yt-recommendation-row">
                    <div class="yt-products-container">
                        <div class="yt-products-header">Your Matches</div>
                        <div class="yt-products-list">
                            <div class="yt-products-scroll-y" v-if="recommendedProducts.length > 0">
                                <div class="yt-products-list-card" v-for="(match, index) in recommendedProducts" :key="match.product._id">
                                    <div class="yt-products-card-btns">
                                        <button class="yt-card-btn yt-btn-plus" @click="adjustQuantity({index, type: 'add'})">+</button>
                                        <button class="yt-card-btn yt-btn-minus" @click="adjustQuantity({index, type: 'minus'})" :disabled="(match.quantity === 0)">-</button>
                                    </div>
                                    <div class="yt-products-card-body" @click="setSelectedMatchItemIndex(index)">
                                        <div class="yt-products-card-image" :style="{opacity: match.quantity === 0 ? '0.3' : '1' }">
                                            <img alt="Product image" :src="getProductImage(match.product)" />
                                        </div>
                                        <div class="yt-products-card-info" :style="{opacity: match.quantity === 0 ? '0.3' : '1' }">
                                            <div class="yt-products-card-info-title">{{match.product.title}}</div>
                                        </div>
                                        <div class="yt-products-card-quantity" :style="{opacity: match.quantity === 0 ? '0.3' : '1' }">
                                            Qty: {{match.quantity}}
                                        </div>
                                        <div class="yt-products-card-icon">
                                        <img alt="chevron right" src="../assets/chevron-right.svg" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="yt-products-scroll-y" v-else>No product matches were found</div>
                        </div>
                    </div>
                    <div class="yt-product-info-container">
                        <div class="yt-products-header">{{ selectedMatchItem?.product ? getMatchScore(selectedMatchItem?.product?.handle, recommendationDetails.reco) : '0%' }} match</div>
                        <div class="yt-product-info-body" v-if="selectedMatchItem?.product">
                            <div class="yt-product-info-left">
                                <div class="yt-product-image">
                                    <img alt="Product image" class="yt-product-image-img" :src="getProductCoverImage(selectedMatchItem?.product)" />
                                    <div class="yt-product-image-title">Essential</div>
                                    <button class="yt-product-image-wishlist" @click="toggleWishedProduct">
                                        <img alt="wishlist" src="../assets/heart-solid.svg" v-if="selectedMatchItem.isfavourite" />
                                        <img alt="wishlist" src="../assets/heart.svg" v-else />
                                    </button>
                                </div>
                                <div class="yt-product-basic">
                                    <div class="yt-product-basic-title">{{selectedMatchItem?.product?.title}}</div>
                                    <div class="yt-product-basic-price">{{selectedMatchItem?.product?.variant.price}}</div>
                                </div>
                                <button class="yt-product-cart-btn">Add to cart</button>
                            </div>
                            <div class="yt-product-info-right">
                                <div class="yt-product-info-column">
                                    <div class="yt-product-info-desc">
                                        <div class="title">Product description:</div>
                                        <div class="yt-product-info-desc_info" v-html="productDesc"></div>
                                    </div>
                                    <div class="yt-product-info-list">
                                        <div>Active(s):</div>
                                        <span v-for="(item, index) in actives">{{item}}{{ index === actives.length - 1 ? '' : ',&nbsp;&nbsp;' }} </span>
                                    </div>
                                    <div class="yt-product-info-list">
                                        <div>Function(s):</div>
                                        <span v-for="(item, index) in functions_">{{item}}{{ index === functions_.length - 1 ? '' : ',&nbsp;&nbsp;' }} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- checkout container -->
            <div class="yt-checkout-container">
                <div class="yt-checkout-info">
                    <span>{{cartCount}}</span>
                    <p>matched products are in the cart</p>
                </div>
                <button class="yt-checkout-btn" @click="emit('checkout')">checkout</button>
            </div>
            <!-- footer -->
                <div class="yt-wishlist" v-if="isWished">
                    {{wishedCount}} Products Added To Wishlist
                </div>
                <div class="yt-wishlist" v-else>
                    <div class="yt-wishlist_form" :class="[isWishing ? 'yt-wishlist_form-is' : '' ]">
                        <input class="yt-wishlist__input" :style="{borderColor: emailValid ? '' : '#ff0000'}" v-model="email" name="email" id="email" placeholder="Enter your email" v-if="isWishing"/>
                        <button class="yt-wishlist__btn" :class="[isWishing ? 'yt-wishlist__btn-active' : '' ]" @click="handleWishButton">
                            <img alt="wishlist" class="yt-wishlist-icon" src="../assets/heart.svg" />
                            <span v-if="hasWishlist">Update wishlist</span>
                            <span v-else>Add all to wishlist</span>
                        </button>

                    </div>
                </div>
        </div>
        <div v-else>
            <div class="yt-analysis-container">
                <div class="yt-analysis-container_row">
                    <div class="yt-analysis-column_image">
                        <div class="yt-products-header">{{ analysisStats.length }} concerns detected</div>
                        <div class="yt-analysis-column_media" v-if="analysisObj.image_data">
                          <canvas :width="canvasSizeX" :height="canvasSizeY" ref="canvas" v-if="quizType === 'skin_care' && analysisObj.image_data"></canvas>
                          <img @load="initializeFaceMesh" :style="{visibility: quizType === 'skin_care' ? 'hidden' : 'visible', ...(quizType === 'skin_care' && { height: '0px'})}" ref="imageElement" :src="analysisObj.image_data"/>
                        </div>
                        <div class="yt-camera-prompt" v-else>
                            <!-- <p>Take a selfie or Upload a photo to get skin analysis. </p> -->
                            <button @click="emit('takeselfie')">TAKE A SELFIE</button>
                        </div>
                    </div>
                    <div class="yt-analysis-column_info">
                        <p class="yt-analysis-info" v-if="analysisObj.error_skin_concerns">{{ analysisObj.error_skin_concerns }}</p>
                        <p class="yt-analysis-info" v-else-if="concernDesc">You have {{ concernDesc?.text }} Skin. {{ concernDesc?.description }}</p>
                        <div class="yt-analysis-stats">
                            <div class="yt-analysis-stats_row" v-for="stat in analysisStats" :key="stat.value">
                                <span class="yt-analysis-stats_row-color" :style="{backgroundColor: stat.color, width: stat.value + 'px'}"></span>
                                <span class="yt-analysis-stats_row-text">{{ stat.text }}</span>
                            </div>
                        </div>
                        <div class="yt-analysis-concerns-preference-row">
                            <div class="yt-analysis-concerns">
                                <span class="yt-analysis-label">Concerns:</span>
                                <div class="yt-analysis-value">
                                    <span v-for="(item, index) in recommendationDetails?.concerns">{{ item }}{{ index === (recommendationDetails?.concerns || []).length - 1 ? '' : ',&nbsp;&nbsp;' }} </span>
                                </div>
                            </div>
                            <div class="yt-analysis-preference">
                                <span class="yt-analysis-label">Preferences:</span>
                                <div class="yt-analysis-value">
                                    <span v-for="(item, index) in preferences">{{ item }}{{ index === preferences.length - 1 ? '' : ',&nbsp;&nbsp;' }} </span>
                                </div>
                            </div>
                        </div>
                        <div class="yt-location">
                            <span class="yt-analysis-label">Location: </span> {{ recommendationDetails?.env_info?.city }} ({{ recommendationDetails?.env_info?.uv_index }}, {{ recommendationDetails?.env_info?.air_quality }}, {{ recommendationDetails?.env_info?.humidity }})
                        </div>
                    </div>
                </div>
                <div class="yt-analysis-container_row yt-analysis-container_row-footer">
                    <button class="yt-analysis-btn_start" @click="emit('startover')">START OVER</button>
                </div>
            </div>
         </div>
    </div>
</template>
