import axios from 'axios';
import { env_, quizTypeApiMap } from "../data";
import type {QuizTypeApiMap} from "@/utils/types";

const DSML_AUTHORIZATION_TOKEN = 'Token 199b59b2ca05013b507c1ca0aab4ebfbeee45a8f';

const getReco = async (id: string, quizType: string) => {
  const apiMap: QuizTypeApiMap = quizTypeApiMap;
  const baseUrl = apiMap[quizType].baseUrl;
  const recoPath = apiMap[quizType].recoPath;
  const url = `${baseUrl}${recoPath}/${id}/${env_}`;

  try {

    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'multipart/form-data;',
        'Authorization': DSML_AUTHORIZATION_TOKEN
      }
    });
    const reco = result.data;
    return reco;
  } catch (e) {
    console.error(e);
    throw e;
  }
};


const imageUpload =  async (quizId: string, imageData: Blob, quizType: string) => {
  const apiMap: QuizTypeApiMap = quizTypeApiMap;
  const baseUrl = apiMap[quizType].baseUrl;
  const imagePath = apiMap[quizType].imagePath;
  const url = `${baseUrl}${imagePath}`;

  try {
    const data = new FormData();
    data.append('quiz_id', quizId);
    data.append('file', imageData);
    data.append('env', env_);

    const result = await axios.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data;',
        Authorization: DSML_AUTHORIZATION_TOKEN
      }
    });
    const analysis = result.data;
    return analysis;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const makeupAdvisorImage =  async (quizId: string, imageData: Blob) => {
  const backendUrl = 'https://tqzwo26fpbhvlu34raqz6xrmy40ikixp.lambda-url.us-west-2.on.aws/v1';
  const url = backendUrl.concat('/upload_image');

  try {
    const data = new FormData();
    data.append('quiz_id', quizId);
    data.append('file', imageData);

    const result = await axios.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data;',
        Authorization: DSML_AUTHORIZATION_TOKEN
      }
    });
    const analysis = result.data;
    return analysis;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export default {getReco, imageUpload, makeupAdvisorImage}

