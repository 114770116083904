<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import SelfieCamera from './SelfieCamera.vue';
import SelfieLoader from './SelfieLoader.vue';
import SelfieAnalysis from './SelfieAnalysis.vue';
import type { Answer } from '../utils/types';

const props = defineProps<{
    skinQuizId: string | null,
    quizType: string,
    isSelfieCamera: boolean,
    isUploadingImage: boolean,
    isImageAnalysis: boolean,
    isCameraSkipped: boolean,
    questionId: string,
    answers: Answer[],
    analysisObj: {}
}>();

const _answers = ref<Answer[]>([]);

const imageUrl = ref('');
const showSkipButton = ref(false);
const quizType = ref('')

const emit = defineEmits(['changesection', 'updateselfiesection', 'updateanswer'])

const showSkipFooter = computed(() => props.isCameraSkipped || (showSkipButton.value && !props.isCameraSkipped))

const handleNav = (type: string) => {
    if (type === 'back') {
        emit('updateselfiesection', {
            isCamera: true,
            isLoading: false,
            isAnalysis: false
        })
    }

    if (type === 'skip' || type === 'goals') emit('changesection', {section: 'quiz', type: 'skip'});

    if (type === 'match') emit('changesection', {section: 'reco', type: 'match'});
}

const getImageAnalysis = ({imageData, imageFile, isUpload}: {
  imageData: string,
  imageFile: Blob,
  isUpload: boolean
}) => {
    imageUrl.value = imageData;
    const _answer: Answer = {
        id: props.questionId,
        type: 'makeup',
        isImage: true,
        isFreetext: false,
        answers: imageData
    }

    _answers.value.push(_answer);
    emit('updateanswer', {_answers: [..._answers.value], imageData, imageFile, isUpload})
}

const getCameraStatus = ({status}: {status: boolean}) => {
    showSkipButton.value = quizType.value !== 'makeup' || status;
}

onMounted(() => {
    console.log('==quiztype', props.quizType)
    quizType.value = props.quizType;
    _answers.value = [...props.answers];
})
</script>

<template>
    <div class="yt-selfie-container">
        <div class="yt-selfie-content">
            <div class="yt-selfie-scroll">
                <SelfieCamera :skinQuizId="skinQuizId" @onCameraStatusChange="getCameraStatus" @onready="getImageAnalysis" />
                <!-- <SelfieLoader :imageUrl="imageUrl" v-if="isUploadingImage" />
                <SelfieAnalysis :analysisObj="analysisObj" :imageUrl="imageUrl" v-if="isImageAnalysis" /> -->
            </div>
        </div>
        <div class="yt-selfie-footer" v-if="showSkipFooter">
            <div class="yt-selfie-footer_col-left">
                <button class="yt-selfie-footer_btn" id="back" @click="handleNav('match')" v-if="isCameraSkipped">
                    <img src="../assets/chevron-left.svg" />
                    Back
                </button>

            </div>
            <div class="yt-selfie-footer_col-right">
                <button class="yt-selfie-footer_btn" id="skip" @click="handleNav('skip')" v-if="showSkipButton && !isCameraSkipped">
                    Skip selfie
                    <img src="../assets/chevron-right.svg" />
                </button>
            </div>
        </div>
    </div>
</template>
