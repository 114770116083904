import type { ProductDetails, Include, RecommendedProductDetails, RecoProduct, StoreProductDetails } from "../types";

const extractImages = (product: ProductDetails, included: Include[] | any[]) => {
    const imageIds = product.relationships.images.data.map((e: { id: string; }) => e.id);
    return included.filter((e: { type: string; id: any; }) => e.type === 'image' && imageIds.includes(e.id));
};

const extractBrand = (product: ProductDetails, included: any[]) => {
    const brandId = product.relationships.brand.data.id;
    const brand = included.find((e: { type: string; id: any; }) => e.type === 'brand' && e.id === brandId);
    return { ...brand.attributes };
};

const extractVariants = (product: ProductDetails, included: any[]) => {
    const variantIds = product.relationships.variants.data.map((e: { id: any; }) => e.id);
    return included.filter((e: { type: string; id: any; }) => e.type === 'variant' && variantIds.includes(e.id)).map((variant: { attributes: { display_price: any; }; id: any; })=>({
        displayPrice: variant.attributes.display_price,
        _id: variant.id })
    );
};

export const extractIdsFromReco = (products: RecoProduct[]) => {
    return products.map(({product_id}) => Number(product_id));
}

export const extractSlugFromReco = (products: RecoProduct[]) => {
    return products.map(({product_slug}) => product_slug);
}

export const deserializeRecommendedProducts = (data: ProductDetails[], included: any): RecommendedProductDetails[] => {
    return data.map((product) => ({
        _id: product.id,
        _variantId: product.relationships.default_variant?.data?.id,
        variantIds: product.relationships.variants.data.map((obj: { id: any; }) => obj.id),
        name: product.attributes.name,
        shortDescription: product.attributes.short_description,
        images: extractImages(product, included),
        brand: extractBrand(product, included),
        variants: extractVariants(product, included),
        slug: product.attributes.slug,
        price: product.attributes.price,
        displayPrice: product.attributes.display_price,
        inStock: product.attributes.in_stock
    }));
};