import type {RecommendationDetails, RecommendedProductDetails, RecoProduct, StoreProductDetails} from "@/utils/types"

export const getProductImage = (product: StoreProductDetails) => {

    const images = product.images;
    let coverImage = images[0];

    images.forEach((img) => {
        if (img.height < coverImage.height)
        coverImage = img;
    });

    return coverImage.src;
};

export const getProductCoverImage = (product: StoreProductDetails) => {
    return product.image.src || '';
}

export const getActives = (handle: string, products: RecoProduct[]): [] => {
    const _product = products.find(item => item.product_slug === handle);
    return _product ? _product.actives_list : [];
}

export const getFunctions = (handle: string, products: RecoProduct[]) => {
    const _product = products.find(item => item.product_slug === handle);
    return _product ? _product.functions_list : [];
}

export const getMatchScore = (handle: string, products: RecoProduct[]) => {
    const _product = products.find(item => item.product_slug === handle);
    return _product ? _product.matching_score : '100%';
}