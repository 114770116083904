
export const removeDuplicatesConcerns = (concerns: string[]) => {
    const _concerns = concerns.reduce((acc: string[], concern: string) => {
        if (concern == 'N/A' || acc.includes(concern)) {
            return acc;
        }

        acc.push(concern);

        return acc
    }, [])

    return _concerns;
}