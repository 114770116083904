<script>
// import Camera from 'easy-js-camera';
import { ref, defineComponent, onMounted, onUnmounted, onBeforeUnmount} from "vue";
import Camera from "../lib/camera";

/*
* FaceMesh global variables
*/
const canvasSize = 550;
let faceMesh = window.FaceMesh ? new window.FaceMesh({
    locateFile: (file) => {
      return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
    },
  }) : null;

export default defineComponent({
    props: {
    },
    components: {},
    setup(props, { emit }) {

        const camera = ref(null);
        const cameraError = ref(null);
        const cameraMessage = ref('Getting Camera ready');
        const webcam = ref(null);
        const canvas = ref(null);
        const activeCamera = ref(false);
        const fileInput = ref(null);
        const distanceMessage = ref('');
        const lightMessage = ref('');

        const canvasSizeY = ref(370);
        const canvasSizeX = ref(370);

        const startCamera = async () => {

            try {
                camera.value = await Camera.tryInvokePermission(webcam.value, canvas.value);
                camera.value.setVideoConstraints({ width: 370, height: 370, facingMode: 'user' });
                camera.value.start();
                cameraError.value = null;
                cameraMessage.value = 'Camera ready';
                activeCamera.value = true;
                emit('onCameraStatusChange', {status: false });
            } catch (e) {
                console.log('camera error', e)
                cameraError.value = 'Could not access camera';
                activeCamera.value = false;
                emit('onCameraStatusChange', {status: true});
                console.error(e);
            }
        };

        const takeSelfie = async () => {
            if (cameraError.value) return;
            const snap = await camera.value.snap();
            const snapAsBlob = await camera.value.snapAsBlob();
            const image = snap.toDataURL();
            emit('onready', {imageData: image, imageFile: snapAsBlob, isUpload: false})
        };

        const stopCamera = async() => {
            activeCamera.value = false;
            
            try {
                if (camera.value) await camera.value.stop();
                camera.value = null;
                return 1;
            } catch (error) {
                console.log('=====>error==>', error)
                throw new Error(error)
            }
        }

        const handleFileUpload = () => {
            fileInput.value.click();
        }

        const onFileSelected = () => {
            const file = fileInput.value.files[0];
            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                const dataUrl = reader.result;
                emit('onready', {imageData: dataUrl, imageFile: file, isUpload: true})
                };
            }
        };

        //  Initializes faceMesh instance
        const initializeFaceMesh = () => {
            if (!faceMesh) {
                faceMesh = window.FaceMesh ? new window.FaceMesh({
                    locateFile: (file) => {
                    return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
                    },
                }) : null;

                if (!faceMesh) return;
            }

            faceMesh.setOptions({
                maxNumFaces: 1,
                minDetectionConfidence: 0.5,
                minTrackingConfidence: 0.5
            });
            faceMesh.onResults(onResults);
        }

        const onResults = (results) => {
            const {
                FACEMESH_TESSELATION,
                FACEMESH_RIGHT_EYE,
                FACEMESH_RIGHT_EYEBROW,
                FACEMESH_LEFT_EYE,
                FACEMESH_LEFT_EYEBROW,
                FACEMESH_FACE_OVAL,
                FACEMESH_LIPS,
                drawConnectors
            } = window;
            const canvasElement = canvas.value;
            const canvasCtx = canvasElement.getContext('2d');

            canvasCtx.save();
            canvasCtx.scale(-1, 1);
            canvasCtx.clearRect(0, 0, canvasSizeX.value, canvasSizeY.value);
            canvasCtx.drawImage(results.image, -canvasSizeX.value, 0, canvasSizeX.value, canvasSizeY.value);
            
            let area;
            let top_left_x;
            let top_left_y;
            let top_right_x;
            let top_right_y;
            let bottom_left_x;
            let bottom_left_y;
            let bottom_right_x;
            let bottom_right_y;

            if (results.multiFaceLandmarks) {
                for (const landmarks of results.multiFaceLandmarks) {
                top_left_x = landmarks[454].x * canvasSizeX.value;
                top_left_y = landmarks[10].y * canvasSizeY.value;
                top_right_x = landmarks[234].x * canvasSizeX.value;
                top_right_y = landmarks[10].y * canvasSizeY.value;
                bottom_left_x = landmarks[454].x * canvasSizeX.value;
                bottom_left_y = landmarks[152].y * canvasSizeY.value;
                bottom_right_x = landmarks[234].x * canvasSizeX.value;
                bottom_right_y = landmarks[152].y * canvasSizeY.value;
                area = (top_right_x - top_left_x) * (top_right_y - bottom_right_y);
                canvasCtx.moveTo(top_left_x, top_left_y);
                canvasCtx.lineTo(top_right_x, top_right_y);
                canvasCtx.stroke();
                canvasCtx.moveTo(top_right_x, top_right_y);
                canvasCtx.lineTo(bottom_right_x, bottom_right_y);
                canvasCtx.stroke();
                canvasCtx.moveTo(bottom_right_x, bottom_right_y);
                canvasCtx.lineTo(bottom_left_x, bottom_left_y);
                canvasCtx.stroke();
                canvasCtx.moveTo(bottom_left_x, bottom_left_y);
                canvasCtx.lineTo(top_left_x, top_left_y);
                canvasCtx.stroke();
                canvasCtx.drawImage(
                    results.image,
                    top_left_x,
                    top_left_y,
                    top_right_x - top_left_x,
                    bottom_left_y - top_left_y,
                    0,
                    0,
                    canvasElement.width,
                    canvasElement.height
                );
                drawConnectors(canvasCtx, landmarks, FACEMESH_TESSELATION, {
                    color: 'white',
                    lineWidth: 1
                });
                drawConnectors(canvasCtx, landmarks, FACEMESH_RIGHT_EYE, {
                    color: '#FF3030',
                    lineWidth: 1
                });
                drawConnectors(canvasCtx, landmarks, FACEMESH_RIGHT_EYEBROW, {
                    color: '#FF3030',
                    lineWidth: 1
                });
                drawConnectors(canvasCtx, landmarks, FACEMESH_LEFT_EYE, {
                    color: '#30FF30',
                    lineWidth: 1
                });
                drawConnectors(canvasCtx, landmarks, FACEMESH_LEFT_EYEBROW, {
                    color: '#30FF30',
                    lineWidth: 1
                });
                drawConnectors(canvasCtx, landmarks, FACEMESH_FACE_OVAL, {
                    color: 'white',
                    lineWidth: 1
                });
                drawConnectors(canvasCtx, landmarks, FACEMESH_LIPS, {
                    color: 'yellow',
                    lineWidth: 1
                });
                }
            }
            canvasCtx.restore();

            const imgData2 = canvasCtx.getImageData(
                top_left_x,
                top_left_y,
                top_right_x - top_left_x,
                top_right_y - bottom_right_y
            );
            const pix = imgData2.data;
            let totalL = 0;
            for (let i = 0, n = pix.length; i < n; i += 4) {
                totalL += pix[i] * 0.3 + pix[i + 1] * 0.59 + pix[i + 2] * 0.11;
            }
            const lower = 1000000;
            const _a = Math.round(3 * 10 ** -9 * area ** 2 - 0.001 * area + 108.6);

            let light;
            let distance;
            if (_a < 45 && totalL < lower) {
                distance = 'You are too close to the camera';
                light = 'The lighting conditions are poor';
            } else if (_a > 70 && totalL < lower) {
                distance = 'You are too far away from the camera';
                light = 'The lighting conditions are poor';
            } else if (_a > 45 && totalL < lower) {
                light = 'The lighting conditions are poor';
                distance = '';
            } else if (_a < 45 && totalL > lower) {
                distance = 'You are too close to the camera';
                light = '';
            } else if (_a < 70 && totalL < lower) {
                light = 'The lighting conditions are poor';
                distance = '';
            } else if (_a > 70 && totalL > lower) {
                distance = 'You are too far away from the camera';
                light = '';
            } else {
                distance = '';
                light = '';
            }
            distanceMessage.value = distance;
            lightMessage.value = light;
        }

        const updateStats = () => {
            if (webcam.value?.paused || !activeCamera.value) {
                return;
            }
            if (faceMesh) {
                window.requestAnimationFrame(async () => {
                    await faceMesh.send({ image: webcam.value }) 
                    updateStats();
                });

            } else {
                initializeFaceMesh();
                updateStats();
            }
        };

        onMounted(async() => {
            faceMesh && initializeFaceMesh();
            startCamera();
        })

        onBeforeUnmount(async() => {
            cameraError.value = ''
            activeCamera.value = false;
            
            try {
                await stopCamera()
            } catch (error) {
                console.log('=====>error==>', error)
            }
        })

        return {
            activeCamera,
            camera,
            cameraError,
            cameraMessage,
            distanceMessage,
            lightMessage,
            canvas,
            webcam,
            fileInput,
            takeSelfie,
            stopCamera,
            handleFileUpload,
            onFileSelected,
            updateStats
        }
    }
});

</script>

<template>
    <div class="yt-selfie-cam-row">
        <div class="yt-selfie-image_col">
           <div class="yt-selfie-image_col-header">{{ cameraError ? cameraError : cameraMessage }}</div>
           <div class="yt-selfie-image_col-media">
                <video
                    ref="webcam"
                    webkit-playsinline="true"
                    autoplay
                    playsinline
                    @playing="updateStats"
                ></video>
                <canvas
                    ref="canvas"
                    style="visibility: hidden; position: absolute; left: 0%;"
                    class="yt-selfie-image_canvas"
                ></canvas>
           </div>
           <div class="yt-selfie-msg">
            <p>{{ distanceMessage }}</p>
            <p>{{ lightMessage }}</p>
           </div>
           <div class="yt-selfie-frame">
               <img src="../assets/selfie-fram.svg" />
           </div>
           <div class="yt-camera_btn-row">
                <button class="yt-camera_btn" @click="takeSelfie()" v-if="activeCamera">
                 <img src="../assets/camera.svg" />
                </button>
            </div>
        </div>
        <div class="yt-slefie-tips_col">
            <div class="yt-slefie-tips_header">
                Camera Tips
            </div>
            <div class="yt-slefie-tips_content">
                <div class="yt-slefie-tips_content-title">
                    Help Yuty make more accurate recommendations by doing the following:
                </div>
                <div class="yt-slefie-tips_reco">
                    <div class="yt-slefie-tips_reco-label">1.</div>
                    <div class="yt-slefie-tips_reco-value">
                        Keep face clear of hair and remove eyewear.
                    </div>
                </div>
                <div class="yt-slefie-tips_reco">
                    <div class="yt-slefie-tips_reco-label">2.</div>
                    <div class="yt-slefie-tips_reco-value">
                        Ensure face is free from makeup.
                    </div>
                </div>
                <div class="yt-slefie-tips_reco">
                    <div class="yt-slefie-tips_reco-label">3.</div>
                    <div class="yt-slefie-tips_reco-value">
                        Ensure there are minimal shadows (natural sunlight if possible).
                    </div>
                </div>
                <div class="yt-slefie-tips_reco">
                    <div class="yt-slefie-tips_reco-label">4.</div>
                    <div class="yt-slefie-tips_reco-value">
                        Keep face centred with a neutral expression.
                    </div>
                </div>
            </div>
            <input type="file" accept=".jpg, .jpeg, .png" ref="fileInput" @change="onFileSelected" style="display: none" />
            <button class="yt-slefie-tips_footer" @click="handleFileUpload">
                or upload a photo
            </button>
        </div>
    </div>
</template>