import axios from 'axios';
import { serializeAnswerOptionsIds, deserializeQuestionsV2 } from '../serializers/quiz';
import type { QuizTypeApiMap } from '@/utils/types';
import { quizTypeApiMap, baseApiUrl } from "../data";

const include = 'answer_options';

const getQuestions = async (quizType: string, storeName: string) => {
  const endpoint = baseApiUrl.concat('questions');
  const apiMap: QuizTypeApiMap = quizTypeApiMap;
  const type_ = apiMap[quizType].type;

  try {
    const result = await axios.get(endpoint, {
      params: {
        include,
        sort: 'position',
        'filter[quiz_type]': type_
      }
    });
    const { data } = result;
    const {questions, answer_options_ids} = deserializeQuestionsV2(data, storeName);
    return {questions, answerOptionsIds: answer_options_ids};
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const getAnswerOptions =async (ids: number[]) => {
  const endpoint = baseApiUrl.concat('answer_options');

  try {
    const result = await axios.get(endpoint, {
      params: {
        'filter[ids]': ids
      }
    });
    const { data } = result.data;
    const options = serializeAnswerOptionsIds(data);
    return options;
  } catch (error) {
    console.error(error);
    throw error;
  }
  
}


export default { getQuestions, getAnswerOptions };