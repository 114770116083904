<script setup lang="ts">
import { ref, onMounted, reactive, type Ref, computed, onUnmounted } from 'vue';
import type {
  AnalysisObject, 
  Answer,
  ImageAnswer, 
  QuizQuestion, 
  RecommendationDetails,
  StoreProductDetails,
  SelfieSectionEvent,
  QuizAnswer,
  QuizType,
  ClientDetails
} from '@/utils/types';
import { 
  createQuiz,
  getQuestions,
  getAnswerOptions, 
  updateAnswer, 
  getReco, 
  getStoreProducts,
  imageUpload,
  getCart
} from '@/utils/api';
import { removeDuplicatesConcerns } from '@/utils/helpers';
import { quizTypeMap } from '@/utils/data'
import { extractSlugFromReco } from "../utils/serializers/products";
import Question from './Question.vue';
import Recommendations from './Recommendations.vue';
import TermsConditions from './TermsConditions.vue';
import Selfie from './Selfie.vue';
import CircularLoader from './CircularLoader.vue';
import Error from './Error.vue';
import Categories from './Categories.vue';

const props = defineProps<{
    isOpen: boolean,
    logo: string,
    client: ClientDetails
}>();

interface CartObj {
    [key: string]: string
}

const emit = defineEmits(['toggleModal'])

const answers = ref<Answer[]>([]);
const isTerms = ref(true);
const isCategories = ref(false)
const isSelfie = ref(false);
const isQuiz = ref(false);
const isReco = ref(false);
const isCircularLoader = ref(false);
const isError = ref(false)
const questions = ref<QuizQuestion[]>([]);
const concernDesc = ref<QuizAnswer | null>(null);
const preferences = ref<string[]>([])

const quizId = ref<string | null>(null);
const skinQuizId = ref<string | null>(null);
const quizType = ref<QuizType>('makeup');
let recommendationDetails: RecommendationDetails = reactive({});
const products: Ref<StoreProductDetails[]> = ref([]);
const storeProducts: Ref<StoreProductDetails[]> = ref([]);

let analysisObj: AnalysisObject = reactive({})
const isSelfieCamera = ref(true);
const cameraPresence = ref(false);
const isUploadingImage = ref(false);
const isImageAnalysis = ref(false);
const isCameraSkipped = ref(false);
const isRecoAnalysis = ref(false);

const ytModal = computed(() => ({
  ...isReco.value &&({'height': '590px'})
}))

const cartObj = ref<CartObj>({})

const imageUrl = ref('');
const isFileUpload = ref(false);

const getRecommendations = ({_answers}: {_answers: Answer[]}) => {
  answers.value = [..._answers];
  fetchReco();
  isReco.value = false;
  isCircularLoader.value = true;
  isQuiz.value = false;
}

const updateImageAnswer = async ({_answers, imageData, imageFile, isUpload}: ImageAnswer) => {
  isSelfieCamera.value = false;
  isUploadingImage.value = true;
  isSelfie.value = false;
  imageUrl.value = imageData;
  isFileUpload.value = isUpload;

  if (isCameraSkipped.value) {
    isCircularLoader.value = true
  } else {
    isQuiz.value = true;
    answers.value = [..._answers];
  }
  try {
    
    if (quizType.value === 'makeup') getSkinImageAnalysis(imageFile, imageData);

    const [analysis_] = skinQuizId.value && quizId.value ? await Promise.all([
      imageUpload(skinQuizId.value, imageFile, quizType.value)
    ]) : [];
    
    if (analysis_ !== 'success') {
      analysisObj = {...analysis_}
    }
    isUploadingImage.value = false;
    if (isCameraSkipped.value) {
      fetchRecoFromSkippedSelfie();
      isCircularLoader.value = false;
      isReco.value = true;
    }
  } catch (error) {
    console.log('=====>error uploading')
    console.log(error);
    isSelfieCamera.value = true;
    isUploadingImage.value = false;
    if (isCameraSkipped.value) {
      isCircularLoader.value = false;
      isReco.value = true;
    }
  }
}

const getSkinImageAnalysis = async (imageFile: Blob, imageData: string) => {
  try {
    const [analysis_] = skinQuizId.value && quizId.value ? await Promise.all([
      imageUpload(skinQuizId.value, imageFile, 'skin_care')
    ]) : [];
    if (analysis_ !== 'success') {
      analysisObj = {...analysis_}
    }
  } catch (error) {
    analysisObj = {...analysisObj, image_data: imageData}
    console.log('=====>error uploading skin image')
    console.log(error);
  }
}

const loadCamera = () => {
  isCameraSkipped.value = false;
  isSelfie.value = true;
  isSelfieCamera.value = true;
  isQuiz.value = false;
  isReco.value = false;
}

const switchSections = ({section, type}: {section: string, type: string}) => {
  if (section === 'quiz') {
     isQuiz.value = true;
     isSelfieCamera.value = false;
     isSelfie.value = false;
  }

  if (type === 'skip') {
    // isCameraSkipped.value = true;
  }

  if (section === 'reco') {
     isSelfie.value = false;
     isReco.value = true;
     //fetchRecoFromSelfie();
  }
}

const updateSelfieSection = ({isCamera, isLoading, isAnalysis}: SelfieSectionEvent) => {
    isSelfieCamera.value = isCamera;
    isUploadingImage.value = isLoading;
    isImageAnalysis.value = isAnalysis
}

const handleTerms = () => {
  if (props.client.categories.length > 1) {
    isCategories.value = true;
  } else {
    setCategory(props.client.categories[0])
  }
  isTerms.value = false;
}

const setCategory = (category: number) => {
  quizType.value = quizTypeMap[category]
  isCategories.value = false;
  fetchQuestions();
  createQuiz_();
}

const fetchQuestions = async () => {
  try {
    isCircularLoader.value = true;
    const res = await getQuestions(quizType.value, props.client.store_name);
    fetchAnswerOptions(res.answerOptionsIds);
    questions.value = [...res.questions]
    isCircularLoader.value = false;
    if (questions.value[0].text === 'Capture Snap') {
      isSelfieCamera.value = true;
      isSelfie.value = true;
      cameraPresence.value = true;
    } else {
      isQuiz.value = true;
      cameraPresence.value = false;
    }
  } catch (e) {
    isCircularLoader.value = false;
    isError.value = true;
    console.error(e);
  }
}

const fetchAnswerOptions = async (answerOptionIds: number[]) => {
  try {
    const res = await getAnswerOptions(answerOptionIds);
    const _questions = questions.value.map((question) => ({
      ...question,
      answers: question.answers.map((answer) => ({
        ...answer,
        image: res[answer.id]
      }))
    }))
    questions.value = [..._questions]
  } catch (error) {
    console.log(error)
  }
  
}

const createQuiz_ = async () => {
  try {
    const [ skinQuiz] = await Promise.all([
      createQuiz(answers.value, quizType.value, props.client.id)
    ]);
    skinQuizId.value = skinQuiz.id;
    quizId.value = skinQuiz.id;
  } catch (e) {
    console.error(e);
  }
}

const fetchReco = async () => {
  console.log("======fetchReco====")
  isCircularLoader.value = true;
  getSkinConcernDesc();
  getPersonalPreference();
  try {
    if (!quizId.value) throw new Error('Quiz Id is missing');

    await updateAnswer(answers.value, quizId.value);
    const recoObj = await getReco(quizId.value, quizType.value);
    recommendationDetails = {
      ...recoObj,
      concerns: recoObj.concerns ? removeDuplicatesConcerns(recoObj.concerns) : recoObj.concerns
    }
    const recoSlugs = (!!recoObj.reco.length || Object.keys(recoObj.reco).length !== 0) ? await extractSlugFromReco(recoObj.reco) : [];
    const recoProducts = recoSlugs.length ? storeProducts.value.filter((product) => recoSlugs.includes(product.handle)) : products.value;
    const activeRecoProducts = recoProducts.length ? recoProducts.filter((product) => product.status === 'active') : []
    let _filteredProducts = activeRecoProducts.length ? activeRecoProducts.map((product) => {
      const _variant = product.variants.find(({inventory_quantity}) => inventory_quantity > 0) || product.variants[0];
      const obj = {
        ...product,
        variant: _variant,
        quantity: 1
      }
      return obj;
    }) : [];
    
    _filteredProducts = _filteredProducts.filter(({variant}) => variant.inventory_quantity > 0)

    products.value = [..._filteredProducts];
    isReco.value = true;
    isCircularLoader.value = false;
  } catch (e) {
    isCircularLoader.value = false;
    isError.value = true;
    console.error(e);
  }
}

const fetchRecoFromSkippedSelfie = async () => {
  console.log("======fetchRecoFromSkippedSelfie====")
  try {
    if (!quizId.value) throw new Error('Quiz Id is missing');

    const recoObj = await getReco(quizId.value, quizType.value);
    recommendationDetails = {
      ...recoObj,
      concerns: recoObj.concerns ? removeDuplicatesConcerns(recoObj.concerns) : recoObj.concerns
    }

    const recoSlugs = !!recoObj.reco.length ? await extractSlugFromReco(recoObj.reco) : [];
    const recoProducts = recoSlugs.length ? storeProducts.value.filter((product) => recoSlugs.includes(product.handle)) : [];
    let _filteredProducts = recoProducts.filter((product) => products.value.findIndex(({handle}) => product.handle === handle) < 0);
    _filteredProducts = _filteredProducts.filter((product) => product.status === 'active');
    _filteredProducts = _filteredProducts.map((product) => {
      const _variant = product.variants.find(({inventory_quantity}) => inventory_quantity > 0) || product.variants[0];
      const obj = {
        ...product,
        variant: _variant,
        quantity: 1
      }
      return obj;
    })
    _filteredProducts = _filteredProducts.filter(({variant}) => variant.inventory_quantity > 0)

    products.value = [...products.value, ..._filteredProducts];
  } catch (e) {
    console.error(e);
  }
}

const getSkinConcernDesc = () => {
  const _questionId = questions.value[1].id;
  const _answers = questions.value[1].answers;
  const _choices = answers.value.find(item => item.id == _questionId)?.answers || [];
  const _answer = _answers.find(item => item.id == _choices[0]) || null;
  concernDesc.value = _answer
}

const getPersonalPreference = () => {
  const _question = questions.value.find(({slug}) => slug === 'SQ15' || slug === 'MQ14');
  if (!_question) {
    preferences.value = [];
    return;
  }
  const _questionId = _question.id;
  const _choices = answers.value.find(item => item.id == _questionId)?.answers as string[] || [];
  const _answer = _question.answers.filter(({id}) => _choices.includes(id)) || [];
  const _answers = _answer.length ? _answer.map(({text}) => text) : []
  preferences.value = _answers
}

const startOver = () => {
  handleTerms();
  isError.value = false;
  isReco.value = false;
  isSelfieCamera.value = true;
  isQuiz.value = false;
  answers.value = [];
  isCameraSkipped.value = false;
}

const takeSelfie = () => {
  isSelfie.value = true;
  isSelfieCamera.value = true;
  isQuiz.value = false;
  isReco.value = false;
  isCameraSkipped.value = true;
  isRecoAnalysis.value = true;
}

const fetchStoreProducts = async () => {
  try {
    const _storeProducst = props.client.store_name ? await getStoreProducts(props.client.store_name) : [];
    storeProducts.value = [..._storeProducst]
  } catch (e) {
    console.error(e);
  }
}

const fetchCartItems = async () => {
    try {
        const res = await getCart();
        console.log('res==cart', res);
        let items_ = res.items;
        items_ = items_.filter((item: any) => item.properties?._source === 'yuty');

        const cart = items_.reduce((acc: any, item: any) => {
            return {...acc, [item.variant_id]: item.key}
        }, {})
        cartObj.value = {...cartObj.value, ...cart};
    } catch (error) {
        console.log('error', error)
    }
}

const goToCart = () => {
  window.location.href = `${window.location.origin}/cart`;
  emit('toggleModal')
}

const imgPath = (name: string) => {
    return new URL(`../assets/${name}`, import.meta.url).href
}

const logo = computed(() => {
    return props.logo !== '' ? props.logo : imgPath('yuty-advisor-logo.svg')
});

onMounted(() => {
  fetchStoreProducts();
  fetchCartItems();
})

onUnmounted(() => {
  isSelfieCamera.value = false;
})

</script>

<template>
  <transition name="modal-fade">
    <div class="yt-modal-overlay" v-if="isOpen">
      <div 
        class="yt-modal"
        :style="ytModal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <div class="yt-header">
          <img alt="logo" class="yt-client-logo" :src="logo" v-if="!isTerms"/>
          <button class="yt-bt-close" @click="$emit('toggleModal')" aria-label="Close modal">
            <img alt="Close Icon" src="../assets/close.svg" />
          </button>
        </div>
        <div class="yt-container">

          <TermsConditions
           :logo=logo 
           @ready="handleTerms" 
           v-if="isTerms"
         />
          <Categories
           :categories="client.categories"
           @setcategory="setCategory"
           v-if="isCategories"
          />
          <Selfie
           :skinQuizId="skinQuizId"
           :quizType="quizType"
           :isSelfieCamera="isSelfieCamera" 
           :isUploadingImage="isUploadingImage" 
           :isImageAnalysis="isImageAnalysis" 
           :analysisObj="analysisObj" 
           :answers="answers" 
           :questionId="questions[0].id"
           :isCameraSkipped="isCameraSkipped"
           @updateselfiesection="updateSelfieSection"
           @changesection="switchSections" 
           @updateanswer="updateImageAnswer" 
           v-if="isSelfie"
          />
          <Question 
           :answers="answers" 
           :questions="questions" 
           :logo="logo" 
           :cameraPresence="cameraPresence"
           @getcamera="loadCamera" 
           @onready="getRecommendations"
           v-if="isQuiz" 
          />
          <Recommendations
           :products="products" 
           :analysisObj="analysisObj" 
           :recommendationDetails="recommendationDetails" 
           :storeProducts="storeProducts"
           :logo="logo" 
           :concernDesc="concernDesc"
           :preferences="preferences"
           :isRecoAnalysis="isRecoAnalysis"
           :clientId="client.id"
           :quizId="quizId"
           :quizType="quizType"
           :isFileUpload="isFileUpload"
           :cartObj="cartObj"
           @startover="startOver" 
           @checkout="goToCart"
           @takeselfie="takeSelfie"
           v-if="isReco" 
          />
          <CircularLoader v-if="isCircularLoader"/>
          <Error @retake="startOver" v-if="isError"/>
        </div>

        <div class="yt-footer">Powered by 
          <img alt="Logo" class="yt-logo" src="../assets/yuty-advisor-logo.svg" />
        </div>
      </div>
    </div>
  </transition>
</template>
